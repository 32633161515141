import { Component, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal.component';
import { ToastComponent } from '../../shared/toast/toast.component';
import { UsuariService } from '../../services/api/usuari.service';
import { lastValueFrom } from 'rxjs';
import { Empresa } from '../../types/Empresa';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ContextService } from '../../services/api/context.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-registre',
  templateUrl: './registre.component.html',
  styleUrls: ['./registre.component.scss'],
})
export class RegistreComponent {
  @ViewChild(ToastComponent)
  toast!: ToastComponent;
  isFocused: boolean = false;
  poblacions: any[] = [];
  infoDesplegada: boolean = false;
  usuariService = inject(UsuariService);
  usuari!: any;
  empresa!: Empresa;
  isWeb: boolean = false;
  seccions: boolean[] = [false, false, false];
  codiPoblacio: string = '';

  constructor(
    private fb: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private contextService: ContextService,
    private service: AuthService
  ) {}

  formData: FormGroup = this.fb.group({
    nomEmpresa: [''],
    nifEmpresa: [''],
    rol: this.fb.array([]),
    emailEmpresa: [''],
    telefonEmpresa: [''],
    adrecaEmpresa: [''],
    codiPostalEmpresa: [''],
    poblacio: [''],
    nifProfessional: [''],
    nomProfessional: [''],
    primerCognom: [''],
    secCognom: [''],
    emailProfessional: [''],
    telefonProfessional: [''],
    acceptarCondicions: [false, Validators.requiredTrue],
  });

  get rol(): FormArray {
    return this.formData.get('rol') as FormArray;
  }

  out() {
    localStorage.removeItem('usuari');
    localStorage.removeItem('usuariEmpreses');
    localStorage.removeItem('empresa');
    localStorage.removeItem('jwt');
    localStorage.removeItem('refresh');
    localStorage.removeItem('uuid');
    window.location.href = './';
  }

  ngOnInit(): void {
    const usuari = localStorage.getItem('usuari') || '{}';
    const empresa = localStorage.getItem('empresa') || '{}';
    if (!usuari || !empresa) {
      this.out();
    }
    this.usuari = JSON.parse(usuari);
    this.empresa = JSON.parse(empresa);
    console.log(this.empresa);
    this.formData = this.fb.group({
      nomEmpresa: [this.empresa.raoSocial, Validators.required],
      nifEmpresa: [
        { value: this.empresa.nif, disabled: true },
        Validators.required,
      ],
      rol: this.fb.array(this.getRols(this.empresa.rol), Validators.required),
      emailEmpresa: [
        this.empresa.adrecaElectronica,
        [Validators.required, Validators.email],
      ],
      telefonEmpresa: [
        this.empresa.telefon,
        [Validators.required, Validators.pattern(/^[0-9]{9}$/)],
      ],
      adrecaEmpresa: [this.empresa.adreca, Validators.required],
      codiPostalEmpresa: [
        this.empresa.codiPostal,
        [Validators.required, Validators.pattern(/^[0-9]{5}$/)],
      ],
      poblacio: [this.empresa.poblacio, Validators.required],
      nifProfessional: [
        { value: this.usuari.dniUsuari, disabled: true },
        Validators.required,
      ],
      nomProfessional: [this.usuari.nom, Validators.required],
      primerCognom: [this.usuari.cognom, Validators.required],
      secCognom: [this.usuari.secCognom],
      emailProfessional: [
        this.usuari.adrecaElectronica,
        [Validators.required, Validators.email],
      ],
      telefonProfessional: [this.usuari.telefon],
      acceptarCondicions: [false, Validators.requiredTrue],
    });

    if (this.usuari && this.empresa) {
      this.formData.patchValue({
        nifProfessional: this.usuari.dniUsuari,
        nifEmpresa: this.empresa.nif,
      });
    } else {
      this.out();
    }

    this.breakpointObserver
      .observe(['(min-width: 916px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isWeb = true;
        }
      });
  }

  getRols(rol: string) {
    if (!rol) {
      return [];
    }
    if (rol.toUpperCase() === 'CARRTRANSP') {
      return ['CARREGADOR', 'TRANSPORTISTA'];
    }
    return [rol.toUpperCase()];
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    setTimeout(() => {
      this.isFocused = false;
      this.poblacions = [];
    }, 200);
  }

  async changeContextPoblacio($event: any) {
    this.codiPoblacio = "";
    if ($event.target.value.length >= 3) {
      this.poblacions = await lastValueFrom(
        this.contextService.getPoblacions($event.target.value)
      );
    }
  }

  setFormValue(value: any) {
    this.formData.patchValue({ poblacio: value.descripcio });
    this.codiPoblacio = value.codiElement;
  }

  isComplete(): boolean {
    for (const field in this.formData.controls) {
      if (this.formData.controls.hasOwnProperty(field)) {
        const control = this.formData.get(field);
        if (control && control.errors && control.errors['required']) {
          return true;
        }
      }
    }
    return false;
  }

  setSeccio(seccio: number) {
    this.seccions[seccio] = !this.seccions[seccio];
  }

  desplegarInfo() {
    this.infoDesplegada = !this.infoDesplegada;
  }

  onRoleChange(role: string, isChecked: boolean) {
    const rolFormArray = this.rol;
    if (isChecked) {
      rolFormArray.push(this.fb.control(role));
    } else {
      const index = rolFormArray.controls.findIndex((x) => x.value === role);
      rolFormArray.removeAt(index);
    }
  }

  async onSubmit() {
    const valid = await this.showValidationErrors();
    if (valid) {
      const rol =
        this.formData.value.rol.length > 1
          ? 'CARRTRANSP'
          : this.formData.value.rol[0];
      const bodyUsuari = {
        dniUsuari: this.usuari.dniUsuari,
        nifEmpresa: this.usuari.nifEmpresa,
        nom: this.formData.value.nomProfessional,
        cognom: this.formData.value.primerCognom,
        secCognom: this.formData.value.secCognom,
        estat: this.usuari.estat,
        perfilId: this.usuari.perfilId,
        adrecaElectronica: this.formData.value.emailProfessional,
        telefon: this.formData.value.telefonProfessional,
        codiPerfil: this.usuari.codiPerfil,
        empresaUsuariId: this.usuari.empresaUsuariId,
        usuariId: this.usuari.usuariId,
        registrat: true,
      };
      const bodyEmpresa = {
        estat: true,
        nif: this.empresa.nif,
        raoSocial: this.formData.value.nomEmpresa,
        adreca: this.formData.value.adrecaEmpresa,
        codiPostal: this.formData.value.codiPostalEmpresa,
        codiPoblacio: this.codiPoblacio,
        adrecaElectronica: this.formData.value.emailEmpresa,
        telefon: this.formData.value.telefonEmpresa,
        rol: rol,
      };
      try {
        const user = await lastValueFrom(
          this.usuariService.registraUsuari(
            bodyUsuari,
            this.usuari.empresaUsuariId
          )
        );
        if (user.validacioResultat) {
          this.errorValidator(user.validacioResultat[0].codiError);
          return;
        }
        localStorage.setItem('usuari', JSON.stringify(bodyUsuari));

        const emp = await lastValueFrom(
          this.usuariService.registraEmpresa(
            bodyEmpresa,
            this.empresa.empresaId
          )
        );
        if (emp.validacioResultat) {
          this.errorValidator(emp.validacioResultat[0].codiError);
          return;
        }
        const empresa = { ...bodyEmpresa, empresaId: this.empresa.empresaId };
        localStorage.setItem('empresa', JSON.stringify(empresa));
        this.service.emitUserLogin();
        window.location.href = './#/inici';
      } catch (err) {
        console.error(err);
        this.errorValidator('VE-AV03');
      }
    }
  }

  errorValidator(codi: string) {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: 'ERRORS.'.concat(codi),
        message: '',
        icon: 'warning',
        showCancel: false,
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: boolean }) => {
        this.dialog.closeAll();
      }
    );
  }

  private async showValidationErrors() {
    const invalidFields: string[] = [];
    const formatErrors: string[] = [];

    Object.keys(this.formData.controls).forEach((field) => {
      const control = this.formData.get(field);
      if (!control) {
        return;
      }
      if (control.invalid) {
        if (control.errors?.['required']) {
          invalidFields.push(field);
        }
        if (control.errors?.['email']) {
          formatErrors.push(`${field}`);
        }
        if (control.errors?.['pattern']) {
          const fieldName = field
            .replace('Empresa', ' de la Empresa')
            .replace('Professional', ' del Profesional');
          formatErrors.push(`${fieldName}`);
        }
      }
    });

    if(!this.codiPoblacio && this.formData.value.poblacio){
      const poblacions = await lastValueFrom(this.contextService.getPoblacions(this.formData.value.poblacio));
      const poblacio = poblacions.find((el: { descripcio: string; }) => el.descripcio === this.formData.value.poblacio);
      if(poblacio) {
        this.codiPoblacio = poblacio.codiElement;
      } else{
        formatErrors.push('poblacio');
      }
    }
    const postalRegex = /^\d{1,5}$/;
    if (!postalRegex.test(this.formData.value.codiPostalEmpresa) && this.formData.value.codiPostalEmpresa) {
      formatErrors.push('codiPostal');
      return;
    }

    let message = '';
    if (invalidFields.length > 0) {
      message += `Per favor, corregeix els següents camps: ${invalidFields.join(
        ', '
      )}. `;
    }
    if (formatErrors.length > 0) {
      message += `Errors de format: ${formatErrors.join(', ')}.`;
    }
    if (invalidFields.length || formatErrors.length) {
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: { message, icon: 'cancel', showCancel: false, title: 'Error' },
      });
      dialogRef.componentInstance.closeEvent.subscribe(() => {
        this.dialog.closeAll();
      });
      return false;
    }
    return true;
  }
}
