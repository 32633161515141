import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Stmc } from '../../types/Stmc';
import { environment } from '../../../environments/environment';
import { StmcPopulated } from '../../types/StmcPopulated';

interface ResponseMultiple {
  dadesResultat: Stmc[];
  dadesPaginacio: any;
  validacioResultat: any;
}
interface ResponseSingle {
  dadesResultat: StmcPopulated;
  validacioResultat: any;
}
interface ResponseDoc {
  dadesResultat: any;
  validacioResultat: any;
}

@Injectable({
  providedIn: 'root',
})
export class StmcService {
  private apiUrl: string = environment.apiUrl.concat('stmc');
  private apiUrlDocument: string = environment.apiUrl.concat('document');

  dcEnviatSubject = new Subject<void>();
  dcEnviatSubject$ = this.dcEnviatSubject.asObservable();

  constructor(private http: HttpClient) {}

  createStmc(Stmc: StmcPopulated): Observable<ResponseSingle> {
    return this.http.post<ResponseSingle>(this.apiUrl, Stmc);
  }

  getStmcById(id: string): Observable<ResponseSingle> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<ResponseSingle>(url);
  }

  stmcFindBy(filters: any): Observable<ResponseMultiple> {
    const url = `${this.apiUrl}/findBy`;
    return this.http.post<ResponseMultiple>(url, filters);
  }

  updateStmc(Stmc: StmcPopulated, id: string): Observable<ResponseSingle> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.put<ResponseSingle>(url, Stmc);
  }

  canviarEstat(Stmc: Stmc, accio: string): Observable<ResponseSingle> {
    const url = `${this.apiUrl}/${Stmc.codiStmc}/${accio}`;
    return this.http.put<ResponseSingle>(url, Stmc);
  }

  deleteStmc(id: number): Observable<void> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete<void>(url);
  }

  generarDc(codiStmc: any): Observable<any> {
    const url = `${this.apiUrlDocument}/${codiStmc}/generar/documentControl`;
    return this.http.post<any>(url, {});
  }

  generarPdfDc(codiStmc: any): Observable<any> {
    const url = `${this.apiUrlDocument}/${codiStmc}/documentControl`;
    return this.http.get<any>(url, {});
  }

  duplicar(codiStmc: any): Observable<ResponseSingle> {
    const url = `${this.apiUrl}/${codiStmc}/duplicar`;
    return this.http.put<ResponseSingle>(url, {});
  }

  pujarDocuments(fitxer: File, document: any): Observable<ResponseSingle> {
    const blob = new Blob([JSON.stringify(document)], {
      type: 'application/json',
    });
    const formData = new FormData();
    formData.append('document', blob);
    formData.append('fitxer', fitxer);
    const url = `${this.apiUrlDocument}`;
    return this.http.post<ResponseSingle>(url, formData);
  }

  getDocuments(body: any): Observable<ResponseDoc> {
    const url = `${this.apiUrlDocument}/findBy`;
    return this.http.post<ResponseDoc>(url, body);
  }

  borrarDocument(id: string): Observable<ResponseSingle> {
    const url = `${this.apiUrlDocument}/${id}`;
    return this.http.delete<ResponseSingle>(url);
  }

  sendByEmail(body: any) {
    const url = `${this.apiUrlDocument}/enviar/documentControl`;
    return this.http.post<ResponseSingle>(url, body);
  }

  getImage(id: string) {
    const url = `${this.apiUrlDocument}/${id}`;
    return this.http.get<ResponseDoc>(url);
  }

  emitDcEnviat() {
    this.dcEnviatSubject.next();
  }
}
