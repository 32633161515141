import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { Empresa } from '../../types/Empresa';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HabitualsListComponent } from '../../shared/habituals/habitualsList.component';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom, Subscription } from 'rxjs';
import { RutaService } from '../../services/api/ruta.service';
import { Ruta } from '../../types/Ruta';
import { TransportistaService } from '../../services/api/transportista.service';
import { Transportista } from '../../types/Transportista';
import { Router, ActivatedRoute } from '@angular/router';
import { HabitualsNewComponent } from '../../shared/habituals/habitualsNew.component';
import { HabitualService } from '../../services/habitual.service';
import { StmcService } from '../../services/api/stmc.service';
import { TranslateService } from '@ngx-translate/core';
import { StmcPopulated } from '../../types/StmcPopulated';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal.component';
import { Vehicle } from '../../types/Vehicle';
import { VehicleService } from '../../services/api/vehicle.service';
import { ContextService } from '../../services/api/context.service';
import { MatriculesComponent } from '../../shared/matricules/matricules.component';

interface MitjaTransport {
  matriculaTractor: string;
  matriculaRemolc: string;
  matriculaTractorSubs1: string;
  matriculaRemolcSubs1: string;
  matriculaTractorSubs2?: string;
  matriculaRemolcSubs2?: string;
}

interface document {
  name: string;
  file?: File;
  status: string;
  url?: string;
  id?: string;
}

@Component({
  selector: 'app-stmc',
  templateUrl: './stmc.component.html',
  styleUrls: ['./stmc.component.scss'],
})
export class StmcComponent implements OnInit {
  @ViewChild('fileInput') fileInput: any;
  private habitualSubscription!: Subscription;

  translate = inject(TranslateService);
  contextService = inject(ContextService);
  rutaService = inject(RutaService);
  transportistaService = inject(TransportistaService);
  stmcService = inject(StmcService);
  vehicleService = inject(VehicleService);
  habitualService = inject(HabitualService);

  currentRoute: string = '';
  codiPoblacio: string = '';
  codiPoblacioOrigen: string = '';
  codiPoblacioDesti: string = '';
  codiProvinciaOrigen: string = '';
  codiProvinciaDesti: string = '';
  codi: string = '';
  id: string = '';
  stmcIdDc: string = '';
  estat: string = 'ESBORRANY';
  estatDc: string = 'DC_NO_CREAT';

  isEdit: boolean = false;
  saving: boolean = false;
  canvis: boolean = false;
  canvisDc: boolean = false;
  copied: boolean = false;

  transportista: Transportista | null = null;
  ruta: Ruta | null = null;
  vehicle: Vehicle | null = null;
  remolc: Vehicle | null = null;

  matricules: MitjaTransport = {
    matriculaTractor: '',
    matriculaRemolc: '',
    matriculaTractorSubs1: '',
    matriculaRemolcSubs1: '',
    matriculaTractorSubs2: '',
    matriculaRemolcSubs2: '',
  };

  transportistes: Transportista[] = [];
  vehicles: Vehicle[] = [];
  rutes: Ruta[] = [];

  resultados: any[] = [];
  poblacions: any[] = [];
  poblacionsOrigen: any[] = [];
  poblacionsDesti: any[] = [];
  mercaderies: any[] = [];

  activeTab: number = 0;
  sections: number[] = [1, 2, 3, 4, 5, 6];

  uploadedFiles: Array<document> = [];
  form: FormGroup = this.fb.group({
    date: '',
    raoSocialCarregador: '',
    nifCarregador: '',
    adrecaCarregador: '',
    codiPostalCarregador: '',
    poblacioCarregador: '',
    observacionsCarregador: '',
    observacionsTransportista: '',
    nifTransportista: '',
    raoSocialTransportista: '',
    originAddress: '',
    originPostalCode: '',
    originCity: '',
    originProvince: '',
    destinationAddress: '',
    destinationPostalCode: '',
    destinationCity: '',
    destinationProvince: '',
    vehicleLicensePlate: '',
    trailerLicensePlate: '',
    goodsType: '',
    goodsWeight: '',
    goodsDescription: '',
    attachedFiles: '',
    requereixAutoritzacio: false,
    numeroAutorizacio: '',
  });

  isFocused = {
    raoSocial: false,
    nif: false,
    poblacio: false,
    nifTrans: false,
    raoSocialTrans: false,
    destinationCity: false,
    originCity: false,
  };
  estats = [
    'ESBORRANY',
    'DC_CREAT',
    'PLANIFICAT',
    'REALIZAT',
    'NO_REALIZAT',
    'REBUTJAT',
    'CANCELAT',
  ];
  provinces: { value: string; label: string }[] = [
    { value: '01', label: 'Álava' },
    { value: '02', label: 'Albacete' },
    { value: '03', label: 'Alicante' },
    { value: '04', label: 'Almería' },
    { value: '05', label: 'Ávila' },
    { value: '06', label: 'Badajoz' },
    { value: '07', label: 'Islas Baleares' },
    { value: '08', label: 'Barcelona' },
    { value: '09', label: 'Burgos' },
    { value: '10', label: 'Cáceres' },
    { value: '11', label: 'Cádiz' },
    { value: '12', label: 'Castellón' },
    { value: '13', label: 'Ciudad Real' },
    { value: '14', label: 'Córdoba' },
    { value: '15', label: 'A Coruña' },
    { value: '16', label: 'Cuenca' },
    { value: '17', label: 'Girona' },
    { value: '18', label: 'Granada' },
    { value: '19', label: 'Guadalajara' },
    { value: '20', label: 'Guipúzcoa' },
    { value: '21', label: 'Huelva' },
    { value: '22', label: 'Huesca' },
    { value: '23', label: 'Jaén' },
    { value: '24', label: 'León' },
    { value: '25', label: 'Lleida' },
    { value: '26', label: 'La Rioja' },
    { value: '27', label: 'Lugo' },
    { value: '28', label: 'Madrid' },
    { value: '29', label: 'Málaga' },
    { value: '30', label: 'Murcia' },
    { value: '31', label: 'Navarra' },
    { value: '32', label: 'Ourense' },
    { value: '33', label: 'Asturias' },
    { value: '34', label: 'Palencia' },
    { value: '35', label: 'Las Palmas' },
    { value: '36', label: 'Pontevedra' },
    { value: '37', label: 'Salamanca' },
    { value: '38', label: 'Santa Cruz de Tenerife' },
    { value: '39', label: 'Cantabria' },
    { value: '40', label: 'Segovia' },
    { value: '41', label: 'Sevilla' },
    { value: '42', label: 'Soria' },
    { value: '43', label: 'Tarragona' },
    { value: '44', label: 'Teruel' },
    { value: '45', label: 'Toledo' },
    { value: '46', label: 'València' },
    { value: '47', label: 'Valladolid' },
    { value: '48', label: 'Vizcaya' },
    { value: '49', label: 'Zamora' },
    { value: '50', label: 'Zaragoza' },
    { value: '51', label: 'Ceuta' },
    { value: '52', label: 'Melilla' },
  ];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.canvis = false;
    this.currentRoute = this.router.url;
    const today = new Date().toISOString().split('T')[0];
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.mercaderies = (
      await lastValueFrom(this.contextService.getTotsMercaderies())
    ).map((el) => {
      if (el.descripcio.length > 47) {
        return {
          ...el,
          descripcio: el.descripcio.substring(0, 47).concat('...'),
        };
      }
      return el;
    });
    if (id) {
      this.isEdit = true;
      this.id = id;
      const { dadesResultat } = await lastValueFrom(
        this.stmcService.getStmcById(this.id)
      );
      const mercaderiaExisteix = this.mercaderies.find(
        (el) => el.descripcio == dadesResultat.mercaderiaStmc.descripcioTipus
      );
      if (!mercaderiaExisteix) {
        this.mercaderies.push({
          codiElement: dadesResultat.mercaderiaStmc.tipusMercaderia,
          descripcio: dadesResultat.mercaderiaStmc.descripcioTipus,
          anexo: dadesResultat.mercaderiaStmc.descripcioMercaderia,
          esborrada: true,
        });
      }
      this.fillForm(dadesResultat);

      // FITXERS
      try {
        const bodyDocuments = {
          dadesPaginacio: {
            numRegisters: 100,
            numPagina: 0,
            ordreCamp: 'nomFitxer',
            ordreAsc: true,
          },
          filtresConsulta: {
            codiStmc: dadesResultat.codiStmc,
            nomesAdjunts: true,
          },
        };
        const fitxers = await lastValueFrom(
          this.stmcService.getDocuments(bodyDocuments)
        );
        const promises = fitxers.dadesResultat.map(async (el: any) => {
          return await lastValueFrom(
            this.stmcService.getImage(el.objectMantId)
          );
        });
        const files = await Promise.all(promises);
        this.uploadedFiles = files.map((el) => {
          const doc = el.dadesResultat;
          const ext = doc.nomFitxer.split('.');
          if (
            ext[ext.length - 1].toLowerCase() == 'jpg' ||
            ext[ext.length - 1].toLowerCase() == 'png' ||
            ext[ext.length - 1].toLowerCase() == 'jpeg'
          ) {
            doc.contingutBase64 = `data:image/${ext[ext.length - 1]};base64,${
              doc.contingutBase64
            }`;
          }
          if (ext[ext.length - 1].toLowerCase() == 'pdf') {
            doc.contingutBase64 = `data:application/pdf;base64,${doc.contingutBase64}`;
          }
          return {
            name: doc.nomFitxer,
            url: doc.contingutBase64,
            id: doc.objectMantId,
            status: 'created',
          };
        });
      } catch (err) {
        console.log('ERROR FITXERS', err);
      }
    } else {
      this.form.patchValue({
        date: today,
      });
      const empresa = JSON.parse(localStorage.getItem('empresa') || '{}');
      if (this.currentRoute.includes('carregador')) {
        const carregador = await lastValueFrom(
          this.contextService.getEmpreses(empresa.nif, 'CARREGADOR')
        );
        this.form.patchValue({
          raoSocialCarregador: empresa.raoSocial,
          nifCarregador: empresa.nif,
          adrecaCarregador: empresa.adreca,
          codiPostalCarregador: empresa.codiPostal,
          poblacioCarregador: carregador[0]?.poblacio || '',
        });
      } else {
        this.form.patchValue({
          nifTransportista: empresa.nif,
          raoSocialTransportista: empresa.raoSocial,
        });
      }
    }

    //HABITUALS

    await this.fetchHabituals('ruta');
    await this.fetchHabituals('vehicle');
    await this.fetchHabituals('transportista');
    this.habitualSubscription = this.habitualService.habitual$.subscribe(
      (res) => {
        const { type, element } = res;
        switch (type) {
          case 'ruta':
            this.selectRuta(element);
            break;
          case 'transportista':
            this.selectTransportista(element);
            break;
          case 'vehicle':
            this.selectVehicle(element);
        }
      }
    );
    this.habitualSubscription = this.habitualService.favorit$.subscribe(
      async (res) => {
        await this.fetchHabituals(res.type);
      }
    );
    this.canvis = false;
  }

  async fetchHabituals(type: string) {
    if (type === 'ruta') {
      const rutes = await lastValueFrom(
        this.rutaService.rutaFindBy({
          dadesPaginacio: {
            numRegisters: 100,
            numPagina: 1,
            ordreList: 'estat',
            ordreAsc: true,
          },
          filtresConsulta: {
            favorit: true,
            estat: true,
          },
        })
      );
      if (rutes.dadesResultat) {
        this.rutes = rutes.dadesResultat;
      }
    }
    if (type === 'transportista') {
      const transportistes = await lastValueFrom(
        this.transportistaService.transportistaFindBy({
          dadesPaginacio: {
            numRegisters: 100,
            numPagina: 1,
            ordreList: 'estat',
            ordreAsc: true,
          },
          filtresConsulta: {
            favorit: true,
            estat: true,
          },
        })
      );
      if (transportistes.dadesResultat) {
        this.transportistes = transportistes.dadesResultat;
      }
    }
    if (type === 'vehicle') {
      const vehicles = await lastValueFrom(
        this.vehicleService.vehicleFindBy({
          dadesPaginacio: {
            numRegisters: 100,
            numPagina: 1,
            ordreList: 'estat',
            ordreAsc: true,
          },
          filtresConsulta: {
            favorit: true,
            estat: true,
          },
        })
      );
      if (vehicles.dadesResultat) {
        this.vehicles = vehicles.dadesResultat;
      }
    }
  }

  async changeContextEmpresa($event: any, rol: string) {
    if ($event.target.value.length >= 3) {
      this.resultados = await lastValueFrom(
        this.contextService.getEmpreses($event.target.value, rol)
      );
    }
  }

  async changeContextPoblacio($event: any, province: string = '') {
    if ($event.target.value.length >= 3) {
      const provincia = this.provinces.find((el) => el.label === province);
      this.poblacions = await lastValueFrom(
        this.contextService.getPoblacions(
          $event.target.value,
          provincia?.value || ''
        )
      );
    }
  }

  onFocus(campo: string) {
    switch (campo) {
      case 'raoSocial':
        this.isFocused.raoSocial = true;
        break;
      case 'nif':
        this.isFocused.nif = true;
        break;
      case 'nifTrans':
        this.isFocused.nifTrans = true;
        break;
      case 'raoSocialTrans':
        this.isFocused.raoSocialTrans = true;
        break;
      case 'poblacio':
        this.isFocused.poblacio = true;
        break;
      case 'destinationCity':
        this.isFocused.destinationCity = true;
        break;
      case 'originCity':
        this.isFocused.originCity = true;
    }
  }

  onBlur(campo: string) {
    setTimeout(() => {
      switch (campo) {
        case 'raoSocial':
          this.isFocused.raoSocial = false;
          this.resultados = [];
          break;
        case 'nif':
          this.isFocused.nif = false;
          this.resultados = [];
          break;
        case 'nifTrans':
          this.isFocused.nifTrans = false;
          this.resultados = [];
          break;
        case 'raoSocialTrans':
          this.isFocused.raoSocialTrans = false;
          this.resultados = [];
          break;
        case 'poblacio':
          this.isFocused.poblacio = false;
          this.poblacions = [];
          break;
        case 'destinationCity':
          this.isFocused.destinationCity = false;
          this.poblacions = [];
          if (!this.form.value.destinationProvince) {
            this.completeProvincia('destinationCity');
          }
          break;
        case 'originCity':
          this.isFocused.originCity = false;
          this.poblacions = [];
          if (!this.form.value.originProvince) {
            this.completeProvincia('originCity');
          }
      }
    }, 200);
  }

  async completeProvincia(camp: string) {
    const city =
      camp === 'destinationCity'
        ? this.form.value.destinationCity
        : this.form.value.originCity;
    const poblacions = await lastValueFrom(
      this.contextService.getPoblacions(city)
    );
    const poblacio = poblacions.find(
      (el) => el.descripcio.toLowerCase() === city.toLowerCase()
    );
    if (poblacio) {
      if (camp === 'originCity') {
        this.codiPoblacioOrigen = poblacio.anexo;
        const prov = this.provinces.find((el) => el.value == poblacio.anexo);
        this.form.patchValue({ originProvince: prov?.label || '' });
      }
      if (camp === 'destinationCity') {
        this.codiPoblacioDesti = poblacio.anexo;
        const prov = this.provinces.find((el) => el.value == poblacio.anexo);
        this.form.patchValue({ destinationProvince: prov?.label || '' });
      }
    }
  }

  setFormValue(campo: string, value: any) {
    this.updateInput();
    let provincia;
    switch (campo) {
      case 'carregador':
        this.form.patchValue({
          raoSocialCarregador: value.raoSocial,
          nifCarregador: value.nif,
          poblacioCarregador: value.poblacio,
          adrecaCarregador: value.adreca,
          codiPostalCarregador: value.cp,
        });
        this.codiPoblacio = value.codiPoblacio;
        break;
      case 'transportista':
        this.form.patchValue({
          nifTransportista: value.nif,
          raoSocialTransportista: value.raoSocial,
        });
        break;
      case 'poblacio':
        this.form.patchValue({ poblacioCarregador: value.descripcio });
        this.codiPoblacio = value.codiElement;
        break;
      case 'originCity':
        provincia = this.provinces.find((el) => el.value === value.anexo);
        this.form.patchValue({
          originCity: value.descripcio,
          originProvince: provincia?.label || '',
        });
        this.codiPoblacioOrigen = value.codiElement;
        break;
      case 'destinationCity':
        provincia = this.provinces.find((el) => el.value === value.anexo);
        this.form.patchValue({
          destinationCity: value.descripcio,
          destinationProvince: provincia?.label || '',
        });
        this.codiPoblacioDesti = value.codiElement;
        break;
    }
  }

  stringToDate(dateString: string) {
    const [day, month, year] = dateString.split('-');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day) + 1);
  }

  async fillForm(stmc: StmcPopulated) {
    this.codi = stmc.codiStmc || '';
    this.stmcIdDc = stmc.stmcIdDc || '';
    this.estat = stmc.estatStmc || 'ESBORRANY';
    this.estatDc = stmc.estatDc || 'DC_NO_CREAT';
    this.matricules = stmc.mitjaTransport;
    this.codiPoblacio = stmc.carregadorContractual.poblacio;
    this.codiPoblacioDesti = stmc.rutaStmc.codiPoblacioDesti || '';
    this.codiPoblacioOrigen = stmc.rutaStmc.codiPoblacioOrigen || '';
    this.codiProvinciaDesti =
      this.provinces.find((el) => el.label === stmc.rutaStmc.provinciaDesti)
        ?.value || '';
    this.codiProvinciaOrigen =
      this.provinces.find((el) => el.label === stmc.rutaStmc.provinciaOrigen)
        ?.value || '';
    this.form.patchValue({
      date: stmc.dataRealitzacio
        ? this.stringToDate(stmc.dataRealitzacio).toISOString().split('T')[0]
        : '',
      estatStmc: stmc.estatStmc,
      estat: true,
      raoSocialCarregador: stmc.carregadorContractual.raoSocial,
      nifCarregador: stmc.carregadorContractual.nif,
      adrecaCarregador: stmc.carregadorContractual.adreca,
      codiPostalCarregador: stmc.carregadorContractual.codiPostal,
      poblacioCarregador: stmc.carregadorContractual.poblacio,
      observacionsCarregador: stmc.carregadorContractual.observacions,
      observacionsTransportista: stmc.transportistaEfectiu.observacions,
      nifTransportista: stmc.transportistaEfectiu.nif,
      raoSocialTransportista: stmc.transportistaEfectiu.raoSocial,
      originAddress: stmc.rutaStmc.adrecaOrigen,
      originPostalCode: stmc.rutaStmc.codiPostalOrigen,
      originCity: stmc.rutaStmc.poblacioOrigen,
      originProvince: stmc.rutaStmc.provinciaOrigen,
      destinationAddress: stmc.rutaStmc.adrecaDesti,
      destinationPostalCode: stmc.rutaStmc.codiPostalDesti,
      destinationCity: stmc.rutaStmc.poblacioDesti,
      destinationProvince: stmc.rutaStmc.provinciaDesti,
      vehicleLicensePlate: stmc.mitjaTransport.matriculaTractor,
      trailerLicensePlate: stmc.mitjaTransport.matriculaRemolc,
      goodsType: stmc.mercaderiaStmc.tipusMercaderia,
      goodsWeight: stmc.mercaderiaStmc.pes
        ? stmc.mercaderiaStmc.pes / 1000
        : null,
      goodsDescription: stmc.mercaderiaStmc.descripcioMercaderia,
      requereixAutoritzacio: stmc.mercaderiaStmc.requereixAutoritzacio || false,
      numeroAutorizacio: stmc.mercaderiaStmc.requereixAutoritzacio
        ? stmc.mercaderiaStmc.numeroAutorizacio
        : '',
      matriculaTractorSubs1: stmc.mitjaTransport.matriculaTractorSubs1,
      matriculaRemolcSubs1: stmc.mitjaTransport.matriculaRemolcSubs1,
      matriculaTractorSubs2: stmc.mitjaTransport.matriculaTractorSubs2,
      matriculaRemolcSubs2: stmc.mitjaTransport.matriculaRemolcSubs2,
    });
  }

  addMatriculaSub() {
    const dialogRef = this.dialog.open(MatriculesComponent, {
      data: { mitjaTransport: this.matricules },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      (result: { accept: boolean; mitjaTransport: MitjaTransport }) => {
        if (result.accept) {
          this.matricules = result.mitjaTransport;
        }
        this.dialog.closeAll();
      }
    );
  }

  openFile = (base64Url = '') => {
    const win = window.open();
    if (win) {
      win.document.write(`
        <style>
          body, html {
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
          }
          iframe {
            border: none;
            height: 100%;
            width: 100%;
          }
        </style>
        <iframe name='doc-stmc' src='${base64Url}'></iframe>
      `);
    }
  };

  setActiveTab(active: number) {
    if (active === this.activeTab) {
      this.activeTab = 0;
      return;
    }
    this.activeTab = active;
  }

  openHabitual(type: string) {
    const dialogRef = this.dialog.open(HabitualsListComponent, {
      data: { type },
    });
    dialogRef.componentInstance.closeEvent.subscribe(() => {
      this.dialog.closeAll();
    });
  }

  updateRadio() {
    this.updateInput();
    this.form.patchValue({
      numeroAutorizacio: '',
    });
  }

  isTabValid(tabIndex: number): boolean {
    switch (tabIndex) {
      case 1: //Dades del carregador contractual
        if (this.currentRoute.includes('carregador')) {
          return true;
        } else {
          return (
            this.form.get('raoSocialCarregador')?.value !== '' &&
            this.form.get('nifCarregador')?.value !== '' &&
            this.form.get('adrecaCarregador')?.value !== '' &&
            this.form.get('codiPostalCarregador')?.value !== '' &&
            this.form.get('poblacioCarregador')?.value !== ''
          );
        }
      case 2: //Dades del transportista efectiu
        if (this.currentRoute.includes('transportista')) {
          return true;
        }
        if (this.transportista !== null) {
          return true;
        }
        return (
          this.form.get('nifTransportista')?.value &&
          this.form.get('raoSocialTransportista')?.value
        );
      case 3: //Dades de la ruta (origen i destinació)
        if (this.ruta !== null) {
          return true;
        }
        return (
          this.form.get('originCity')?.value &&
          this.form.get('destinationCity')?.value
        );
      case 4: //Dades dels vehicles
        return this.form.get('vehicleLicensePlate')?.value !== '';
      case 5: //Dades de la mercaderia
        return this.form.get('goodsType')?.value !== '';
      default:
        return false;
    }
  }

  fileInputClick() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any) {
    this.updateInput();
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.name.length >= 35) {
        const dialogRef = this.dialog.open(ErrorModalComponent, {
          data: {
            title: 'MODAL.FITXER_LONG',
            message: file.name,
            icon: 'cancel',
            showCancel: false,
          },
        });
        dialogRef.componentInstance.closeEvent.subscribe(() => {
          this.dialog.closeAll();
        });
        return;
      }
      if (file.size <= 1073741824) {
        const fileData: document = {
          name: file.name,
          file: file,
          status: 'new',
        };
        if (
          file.type.startsWith('image/') ||
          file.type.startsWith('application/pdf')
        ) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            fileData.url = e.target.result;
            this.uploadedFiles.push(fileData);
          };
          reader.readAsDataURL(file);
        } else {
          this.uploadedFiles.push(fileData);
        }
      }
    }
  }

  removeFile(index: number) {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: 'MODAL.BORRAR_FITXER',
        message: this.uploadedFiles[index].name,
        icon: 'cancel',
        showCancel: true,
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      (result: { accept: boolean }) => {
        if (result.accept) {
          if (this.uploadedFiles[index].status !== 'new') {
            this.uploadedFiles[index].status = 'delete';
            this.canvis = true;
          } else {
            this.uploadedFiles.splice(index, 1);
          }
        }
        this.dialog.closeAll();
      }
    );
  }

  isSelectedRuta(obj: Ruta) {
    if (this.ruta === null) return '';
    return obj.objectMantId === this.ruta.objectMantId ? 'selected' : '';
  }
  isSelectedVehicle(obj: Vehicle) {
    if (obj.tipusVehicle === 'TRACTOR') {
      const idVehicle = this.vehicle?.objectMantId || '';
      return obj.objectMantId === idVehicle ? 'selected' : '';
    }
    if (obj.tipusVehicle === 'REMOLC') {
      const idRemolc = this.remolc?.objectMantId || '';
      return obj.objectMantId === idRemolc ? 'selected' : '';
    }
    return '';
  }
  isSelectedTrans(obj: Transportista) {
    if (this.transportista === null) return '';
    return obj.objectMantId === this.transportista.objectMantId
      ? 'selected'
      : '';
  }

  async selectRuta(ruta: Ruta) {
    this.ruta = ruta;
    const provinciaOrigen = this.provinces.find(
      (el) => el.value === ruta.codiProvinciaOrigen
    );
    const provinciaDesti = this.provinces.find(
      (el) => el.value === ruta.codiProvinciaDesti
    );
    this.form.patchValue({
      originAddress: ruta.adrecaOrigen,
      originPostalCode: ruta.codiPostalOrigen,
      originCity: ruta.poblacioOrigen,
      originProvince: provinciaOrigen?.label || '',
      destinationAddress: ruta.adrecaDesti,
      destinationPostalCode: ruta.codiPostalDesti,
      destinationCity: ruta.poblacioDesti,
      destinationProvince: provinciaDesti?.label || '',
    });
    this.codiPoblacioOrigen = ruta.codiPoblacioOrigen;
    this.codiPoblacioDesti = ruta.codiPoblacioDesti;
    this.codiProvinciaOrigen = provinciaOrigen?.value || '';
    this.codiProvinciaDesti = provinciaDesti?.value || '';
    this.updateInput();
  }

  selectVehicle(vehicle: Vehicle) {
    if (vehicle.tipusVehicle === 'TRACTOR') {
      this.form.patchValue({
        vehicleLicensePlate: vehicle.matricula,
      });
      this.vehicle = vehicle;
    }
    if (vehicle.tipusVehicle === 'REMOLC') {
      this.form.patchValue({
        trailerLicensePlate: vehicle.matricula,
      });
      this.remolc = vehicle;
    }
    this.updateInput();
  }

  selectTransportista(transportista: Transportista) {
    this.transportista = transportista;
    this.form.patchValue({
      nifTransportista: transportista.nifTransportista,
      raoSocialTransportista: transportista.raoSocial,
    });
    this.updateInput();
  }

  iconaTipus(tipus: string) {
    switch (tipus) {
      case 'DC_MODIFICACIO':
        return 'sd_card_alert';
      case 'DC_CREAT':
        return 'task';
      case 'DC_NO_CREAT':
        return 'error_outline';
      default:
        return 'error_outline';
    }
  }

  goBack() {
    const ruta = this.currentRoute.includes('transportista')
      ? 'transportista'
      : 'carregador';
    localStorage.setItem('recuperar', 'si');
    if (this.canvis) {
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: {
          title: 'MODAL.SORTIR_DESAR',
          icon: 'warning',
          showCancel: true,
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(
        (result: { accept: boolean }) => {
          if (result.accept) {
            this.router.navigate([`/${ruta}`]);
          }
          this.dialog.closeAll();
          localStorage.setItem('recuperar', '');
        }
      );
    } else {
      this.router.navigate([`/${ruta}`]);
    }
  }

  getStatus() {
    return this.form.get('status')?.value.toLowerCase() || '';
  }

  async addVehicle(tipus: string) {
    const matricula =
      tipus === 'REMOLC'
        ? this.form.get('trailerLicensePlate')?.value
        : this.form.get('vehicleLicensePlate')?.value;
    const matriculaRegex = /^[a-zA-Z0-9]{0,10}$/;
    if (!matriculaRegex.test(matricula)) {
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: {
          title: 'MODAL.FORMAT_INVALID',
          message: 'CARREGADOR.MATRICULA',
          icon: 'cancel',
          showCancel: false,
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(
        (result: { accept: boolean }) => {
          this.dialog.closeAll();
        }
      );
      return;
    }
    if (matricula !== '') {
      const newVehicle = {
        estat: true,
        nifEmpresa: this.form.get('nifCarregador')?.value,
        matricula,
        tipusVehicle: tipus,
        favorit: true,
      };
      const dialogRef = this.dialog.open(HabitualsNewComponent, {
        data: { type: 'vehicle', data: newVehicle },
      });
      dialogRef.componentInstance.closeEvent.subscribe(
        (result: { save: boolean; data: Vehicle }) => {
          this.dialog.closeAll();
          if (result.save) {
            this.vehicles.push(result.data);
            this.vehicle = result.data;
          }
        }
      );
    } else {
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: {
          title: 'MODAL.OMPLI_OBLIGATORI',
          icon: 'cancel',
          showCancel: false,
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(
        (result: { accept: boolean }) => {
          this.dialog.closeAll();
        }
      );
    }
  }

  async addRuta() {
    const postalRegex = /^\d{1,5}$/;
    if (
      this.form.get('originCity')?.value !== '0' &&
      this.form.get('destinationCity')?.value !== '0' &&
      this.form.get('originCity')?.value !== '' &&
      this.form.get('destinationCity')?.value !== ''
    ) {
      const codiPostalInvalid = () => {
        const dialogRef = this.dialog.open(ErrorModalComponent, {
          data: {
            title: 'MODAL.FORMAT_INVALID',
            message: 'STMC.POSTAL_CODE',
            icon: 'cancel',
            showCancel: false,
          },
        });
        dialogRef.componentInstance.closeEvent.subscribe(
          (result: { accept: boolean }) => {
            this.dialog.closeAll();
          }
        );
      };
      if (
        !postalRegex.test(this.form.get('destinationPostalCode')?.value) &&
        this.form.get('destinationPostalCode')?.value
      ) {
        codiPostalInvalid();
        return;
      }
      if (
        !postalRegex.test(this.form.get('originPostalCode')?.value) &&
        this.form.get('originPostalCode')?.value
      ) {
        codiPostalInvalid();
        return;
      }
      const newRuta = {
        estat: true,
        nifEmpresa: this.form.get('nifCarregador')?.value,
        descripcio: '',

        codiPoblacioOrigen: this.codiPoblacioOrigen,
        poblacioOrigen: this.form.get('originCity')?.value,
        codiPostalOrigen: this.form.get('originPostalCode')?.value,
        adrecaOrigen: this.form.get('originAddress')?.value,

        codiPoblacioDesti: this.codiPoblacioDesti,
        poblacioDesti: this.form.get('destinationCity')?.value,
        codiPostalDesti: this.form.get('destinationPostalCode')?.value,
        adrecaDesti: this.form.get('destinationAddress')?.value,

        favorit: true,
      };

      const dialogRef = this.dialog.open(HabitualsNewComponent, {
        data: { type: 'ruta', data: newRuta },
      });
      dialogRef.componentInstance.closeEvent.subscribe(
        (result: { save: boolean; data: Ruta }) => {
          this.dialog.closeAll();
          if (result.save) {
            this.rutes.push(result.data);
            this.ruta = result.data;
          }
        }
      );
    } else {
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: {
          title: 'MODAL.OMPLI_OBLIGATORI',
          message: 'DC.POBLACIO_LABEL',
          icon: 'cancel',
          showCancel: false,
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(
        (result: { accept: boolean }) => {
          this.dialog.closeAll();
        }
      );
    }
  }

  async addTransportista() {
    if (
      this.form.get('nifTransportista')?.value !== '' &&
      this.form.get('raoSocialTransportista')?.value !== ''
    ) {
      const newTransportista = {
        estat: true,
        nifCarregador: this.form.get('nifCarregador')?.value,
        nifTransportista: this.form.get('nifTransportista')?.value,
        observacions: this.form.get('observacionsTransportista')?.value,
        raoSocial: this.form.get('raoSocialTransportista')?.value,
        favorit: true,
      };

      const dialogRef = this.dialog.open(HabitualsNewComponent, {
        data: { type: 'transportista', data: newTransportista },
      });
      dialogRef.componentInstance.closeEvent.subscribe(
        (result: { save: boolean; data: Transportista }) => {
          this.dialog.closeAll();
        }
      );
    } else {
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: {
          title: 'MODAL.OMPLI_OBLIGATORI',
          icon: 'cancel',
          showCancel: false,
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(
        (result: { accept: boolean }) => {
          this.dialog.closeAll();
        }
      );
    }
  }

  areTabsValid() {
    return (
      this.isTabValid(5) &&
      this.isTabValid(1) &&
      this.isTabValid(2) &&
      this.isTabValid(3) &&
      this.isTabValid(4)
    );
  }

  updateInput() {
    this.canvis = true;
    this.canvisDc = true;
  }

  updateInputPOrigen() {
    this.updateInput();
    this.codiPoblacioOrigen = '';
  }

  updateInputPDesti() {
    this.updateInput();
    this.codiPoblacioDesti = '';
  }

  updateInputPCarregador() {
    this.updateInput();
    this.codiPoblacio = '';
  }

  copyCode(codi: string) {
    navigator.clipboard.writeText(codi);
    this.copied = true;
  }

  async dcPrevisualitzacio() {
    if (await this.saveSmtc()) {
      const ruta = this.currentRoute.includes('transportista')
        ? 'transportista'
        : 'carregador';
      window.location.href = `./#/${ruta}/dc/${this.id}/previsualitzacio`;
    }
  }

  async dcActual() {
    const ruta = this.currentRoute.includes('transportista')
      ? 'transportista'
      : 'carregador';
    window.location.href = `./#/${ruta}/dc/${this.stmcIdDc}/generat`;
  }

  checkLletres() {
    const value = this.form.value.goodsWeight.replace(/[^0-9.,-]/g, '');
    this.form.patchValue({ goodsWeight: value });
  }

  errorValidacio(codi: string, message: string = '') {
    this.saving = false;
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: 'ERRORS.'.concat(codi),
        message,
        icon: 'cancel',
        showCancel: false,
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      (result: { accept: boolean }) => {
        this.dialog.closeAll();
      }
    );
  }

  updateProvince(event: any, type: string) {
    this.updateInput();
    const prov = this.provinces.find((el) => el.label === event.target.value);
    const provValue = prov?.value || '';
    if (type === 'origin') {
      this.codiProvinciaOrigen = provValue;
      this.codiPoblacioOrigen = '';
      this.form.patchValue({ originCity: '' });
    }
    if (type === 'destination') {
      this.codiProvinciaDesti = provValue;
      this.codiPoblacioDesti = '';
      this.form.patchValue({ destinationCity: '' });
    }
  }

  async saveSmtc() {
    this.saving = true;
    const values = this.form.value;
    const date = values.date.split('-');
    const rol = this.currentRoute.includes('carregador')
      ? 'CARREGADOR'
      : 'TRANSPORTISTA';

    const obtindrePoblacio = async (str: string, provincia: string) => {
      const poblacions = await lastValueFrom(
        this.contextService.getPoblacions(str, provincia)
      );
      const poblacio = poblacions.find((el) => el.descripcio === str);
      return poblacio ? poblacio.codiElement : '';
    };
    if (values.originCity) {
      this.codiPoblacioOrigen = await obtindrePoblacio(
        values.originCity,
        this.codiProvinciaOrigen
      );
      if (!this.codiPoblacioOrigen) {
        this.errorValidacio('POB-O');
        this.saving = false;
        return false;
      }
    }
    if (values.destinationCity) {
      this.codiPoblacioDesti = await obtindrePoblacio(
        values.destinationCity,
        this.codiProvinciaDesti
      );
      if (!this.codiPoblacioDesti) {
        this.errorValidacio('POB-D');
        this.saving = false;
        return false;
      }
    }
    if (values.poblacioCarregador) {
      this.codiPoblacio = await obtindrePoblacio(values.poblacioCarregador, '');
      if (!this.codiPoblacio) {
        this.errorValidacio('POB-C');
        this.saving = false;
        return false;
      }
    }

    const newstmc = {
      estat: true,
      codiStmc: this.codi,
      estatStmc: this.estat,
      rolSeleccionat: rol,
      dataRealitzacio: `${date[2]}-${date[1]}-${date[0]}`,
      rutaStmc: {
        codiPoblacioOrigen: this.codiPoblacioOrigen || '',
        codiPoblacioDesti: this.codiPoblacioDesti || '',
        poblacioOrigen: values.originCity || '',
        poblacioDesti: values.destinationCity || '',
        provinciaOrigen: values.originProvince || '',
        codiPostalOrigen: values.originPostalCode || '',
        adrecaOrigen: values.originAddress || '',
        provinciaDesti: values.destinationProvince || '',
        codiPostalDesti: values.destinationPostalCode || '',
        adrecaDesti: values.destinationAddress || '',
      },
      carregadorContractual: {
        nif: values.nifCarregador?.toUpperCase() || '',
        raoSocial: values.raoSocialCarregador || '',
        adreca: values.adrecaCarregador || '',
        codiPostal: values.codiPostalCarregador || '',
        poblacio: values.poblacioCarregador || '',
        codiPoblacio: this.codiPoblacio || '',
        observacions: values.observacionsCarregador || '',
      },
      transportistaEfectiu: {
        nif: values.nifTransportista?.toUpperCase() || '',
        raoSocial: values.raoSocialTransportista || '',
        observacions: values.observacionsTransportista || '',
      },
      mitjaTransport: {
        matriculaTractor: values.vehicleLicensePlate || '',
        matriculaRemolc: values.trailerLicensePlate || '',
        matriculaTractorSubs1: this.matricules.matriculaTractorSubs1 || '',
        matriculaRemolcSubs1: this.matricules.matriculaRemolcSubs1 || '',
        matriculaTractorSubs2: this.matricules.matriculaTractorSubs2 || '',
        matriculaRemolcSubs2: this.matricules.matriculaRemolcSubs2 || '',
      },
      mercaderiaStmc: {
        tipusMercaderia: values.goodsType || '',
        descripcioMercaderia: values.goodsDescription || '',
        descripcioTipus:
          this.mercaderies.find((el) => el.codiElement == values.goodsType)
            ?.descripcio || '',
        pes: this.form.value.goodsWeight
          ? this.form.value.goodsWeight * 1000
          : null,
        requereixAutoritzacio: values.requereixAutoritzacio || '',
        numeroAutorizacio: values.numeroAutorizacio || '',
      },
    };

    if (
      newstmc.carregadorContractual.nif === newstmc.transportistaEfectiu.nif
    ) {
      this.errorValidacio('CC-TE');
      return false;
    }
    const pes = newstmc.mercaderiaStmc.pes;
    if (pes && pes.toString().length > 15) {
      this.errorValidacio('NUM-PES');
      return false;
    }
    const numAuth = newstmc.mercaderiaStmc.numeroAutorizacio;
    if (numAuth) {
      if (numAuth.length > 15 || !/^[a-zA-Z0-9]+$/.test(numAuth)) {
        this.errorValidacio('NUM-AUT');
        return false;
      }
    }
    if (
      newstmc.mitjaTransport.matriculaRemolc &&
      !/^[a-zA-Z0-9]+$/.test(newstmc.mitjaTransport.matriculaRemolc)
    ) {
      this.errorValidacio('MAT-R');
      return false;
    }
    if (
      newstmc.mitjaTransport.matriculaTractor &&
      !/^[a-zA-Z0-9]+$/.test(newstmc.mitjaTransport.matriculaTractor)
    ) {
      this.errorValidacio('MAT-T');
      return false;
    }
    if (
      (!newstmc.mitjaTransport.matriculaRemolc &&
        (newstmc.mitjaTransport.matriculaRemolcSubs1 ||
          newstmc.mitjaTransport.matriculaRemolcSubs2)) ||
      (!newstmc.mitjaTransport.matriculaTractor &&
        (newstmc.mitjaTransport.matriculaTractorSubs1 ||
          newstmc.mitjaTransport.matriculaTractorSubs2))
    ) {
      this.errorValidacio('MAT-SUB');
      return false;
    }
    if (
      (!newstmc.mitjaTransport.matriculaRemolcSubs1 &&
        newstmc.mitjaTransport.matriculaRemolcSubs2) ||
      (!newstmc.mitjaTransport.matriculaTractorSubs1 &&
        newstmc.mitjaTransport.matriculaTractorSubs2)
    ) {
      this.errorValidacio('MAT-SUB3');
      return false;
    }
    if (
      (newstmc.mitjaTransport.matriculaRemolcSubs1 ===
        newstmc.mitjaTransport.matriculaRemolc &&
        newstmc.mitjaTransport.matriculaRemolc) ||
      (newstmc.mitjaTransport.matriculaTractor ===
        newstmc.mitjaTransport.matriculaTractorSubs1 &&
        newstmc.mitjaTransport.matriculaTractor)
    ) {
      this.errorValidacio('MAT-SUB1');
      return false;
    }
    if (
      (newstmc.mitjaTransport.matriculaRemolcSubs1 ===
        newstmc.mitjaTransport.matriculaRemolcSubs2 &&
        newstmc.mitjaTransport.matriculaRemolcSubs1) ||
      (newstmc.mitjaTransport.matriculaTractorSubs2 ===
        newstmc.mitjaTransport.matriculaTractorSubs1 &&
        newstmc.mitjaTransport.matriculaTractorSubs2)
    ) {
      this.errorValidacio('MAT-SUB2');
      return false;
    }
    if (
      newstmc.rutaStmc.codiPostalOrigen &&
      !/^\d{1,5}$/.test(newstmc.rutaStmc.codiPostalOrigen)
    ) {
      this.errorValidacio('CP-O');
      return false;
    }
    if (
      newstmc.rutaStmc.codiPostalDesti &&
      !/^\d{1,5}$/.test(newstmc.rutaStmc.codiPostalDesti)
    ) {
      this.errorValidacio('CP-D');
      return false;
    }
    if (
      newstmc.carregadorContractual.codiPostal &&
      !/^\d{1,5}$/.test(newstmc.carregadorContractual.codiPostal)
    ) {
      this.errorValidacio('CP-C');
      return false;
    }
    try {
      let newStmcResult;
      if (this.isEdit) {
        if (this.id) {
          newStmcResult = await lastValueFrom(
            this.stmcService.updateStmc(newstmc, this.id)
          );
          if (newStmcResult.dadesResultat) {
            this.id = newStmcResult.dadesResultat.objectMantId || '';
            this.estat = newStmcResult.dadesResultat.estatStmc;
          }
          if (newStmcResult.validacioResultat) {
            this.errorValidacio(
              newStmcResult.validacioResultat[0].codiError,
              newStmcResult.validacioResultat[0].missatge
            );
            return false;
          }
        }
      } else {
        newStmcResult = await lastValueFrom(
          this.stmcService.createStmc(newstmc)
        );
        if (newStmcResult.dadesResultat) {
          this.isEdit = true;
          this.id = newStmcResult.dadesResultat.objectMantId || '';
          this.codi = newStmcResult.dadesResultat.codiStmc || '';
          this.estat = newStmcResult.dadesResultat.estatStmc;
        }
        if (newStmcResult.validacioResultat) {
          this.errorValidacio(
            newStmcResult.validacioResultat[0].codiError,
            newStmcResult.validacioResultat[0].missatge
          );
          return false;
        }
      }
      const stmcCreat = newStmcResult?.dadesResultat || {
        codiStmc: '',
        carregadorContractual: { nif: '' },
        transportistaEfectiu: { nif: '' },
      };

      const promeses = this.uploadedFiles
        .filter((el) => el.status === 'new')
        .map(async (doc) => {
          if (doc.file) {
            const pujada = await lastValueFrom(
              this.stmcService.pujarDocuments(doc.file, {
                codiStmc: stmcCreat.codiStmc,
                nifEmpresa:
                  rol == 'CARREGADOR'
                    ? stmcCreat.carregadorContractual.nif
                    : stmcCreat.transportistaEfectiu.nif,
                observacions: '',
              })
            );
            if (pujada.validacioResultat) {
              this.errorValidacio(
                pujada.validacioResultat[0].codiError,
                pujada.validacioResultat[0].missatge
              );
            }
          }
        });
      await Promise.all(promeses);
      const promesesDelete = this.uploadedFiles
        .filter((el) => el.status === 'delete')
        .map(async (doc) => {
          if (doc.id) {
            await lastValueFrom(this.stmcService.borrarDocument(doc.id));
          }
        });
      await Promise.all(promesesDelete);
    } catch (err) {
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: {
          title: 'MODAL.ERROR_DESAR',
          icon: 'cancel',
          showCancel: false,
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(
        (result: { accept: boolean }) => {
          this.dialog.closeAll();
        }
      );
      this.saving = false;
      return false;
    }
    this.canvis = false;
    this.saving = false;
    if (this.estatDc === 'DC_CREAT') {
      this.estatDc = 'DC_MODIFICACIO';
    }
    return true;
  }

  textTipus(value: string) {
    if (!value) {
      return '';
    }
    return `CARREGADOR.${value}`;
  }
}
