import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  private apiUrl: string = `${environment.apiUrl}contextuals`;
  
  constructor(private http: HttpClient) {}

  quitarTildes = (str: string) => {
    if(str.includes("/")) {
      str = str.split("/")[0]
    }
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  getEmpreses(filtre: string, rol: string = ""): Observable<any[]> {
    const url = rol ? `${this.apiUrl}/empreses/${this.quitarTildes(filtre)}?rol=${rol}` : `${this.apiUrl}/empreses/${filtre}`;
    return this.http.get<any[]>(url);
  }

  getMercaderies(filtre: string): Observable<any[]> {
    const url = `${this.apiUrl}/mercaderias/${this.quitarTildes(filtre)}`;
    return this.http.get<any[]>(url);
  }

  getTotsMercaderies(): Observable<any[]> {
    const url = `${this.apiUrl}/totsMercaderias`;
    return this.http.get<any[]>(url);
  }

  getPoblacions(filtre: string, prov: string = ""): Observable<any[]> {
    const url = prov ? `${this.apiUrl}/poblacions/${this.quitarTildes(filtre)}?codiProvincia=${prov}` : `${this.apiUrl}/poblacions/${this.quitarTildes(filtre)}`;
    return this.http.get<any[]>(url);
  }

  getPoblacion(id: string): Observable<any> {
    const url = `${this.apiUrl}/poblacio/${this.quitarTildes(id)}`;
    return this.http.get<any>(url);
  }

  getPoblacionsProvincia(prov: string): Observable<any[]> {
    if(prov.length === 1){
        prov = `0${prov}`
    }
    const url = `${this.apiUrl}/poblacionsProvincia/${prov}`;
    return this.http.get<any[]>(url);
  }
 
}
