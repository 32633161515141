import { Component, Inject, EventEmitter, Output, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Transportista } from '../../types/Transportista';
import { Ruta } from '../../types/Ruta';
import { TransportistaService } from '../../services/api/transportista.service';
import { RutaService } from '../../services/api/ruta.service';
import { lastValueFrom } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VehicleService } from '../../services/api/vehicle.service';
import { Vehicle } from '../../types/Vehicle';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { HabitualService } from '../../services/habitual.service';
import { ContextService } from '../../services/api/context.service';

@Component({
  selector: 'app-habituals-new',
  template: `
    <div class="ctti-modal text-left" [formGroup]="form">
      <div class="cross" (click)="closeModal()">
        <mat-icon> close </mat-icon>
      </div>
      <h5>{{ 'MODAL.HABITUALS_NEW' | translate }}</h5>
      <p>{{ 'MODAL.HABITUALS_NEW_SUB' | translate }}</p>
      <br />
      <input
        type="text"
        id="alias"
        class="ctti-input-text"
        formControlName="alias"
        placeholder="{{ 'STMC.ALIAS' | translate }}"
      />
      <br />
      <div class="botones">
        <button
          class="ctti-button ctti-button-trans"
          mat-dialog-close
          (click)="closeModal()"
        >
          {{ 'MODAL.CANCEL' | translate }}
        </button>
        <button
          class="ctti-button ctti-button-filled"
          mat-dialog-close
          (click)="closeModal(true)"
        >
          {{ 'MODAL.SAVE' | translate }}
        </button>
      </div>
    </div>
  `,
})
export class HabitualsNewComponent {
  @Output() closeEvent = new EventEmitter<{
    save: boolean;
    data: Transportista | Ruta | Vehicle;
  }>();
  rutaService = inject(RutaService);
  transportistaService = inject(TransportistaService);
  vehicleService = inject(VehicleService);
  habitualService = inject(HabitualService);
  contextService = inject(ContextService);
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: string; data: any },
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.form = this.fb.group({
      alias: [''],
    });
  }

  errorValidator(error: any) {
    const callback = () => {
      const dialogRef = this.dialog.open(HabitualsNewComponent, {
        data: {
          type: this.data.type,
          data: this.data.data,
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(() => {
        (result: { save: boolean }) => {
          if (result.save) {
            this.habitualService.emitFavotit(this.data.type, this.data.data);
          }
          this.dialog.closeAll();
        };
      });
    };
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: 'ERRORS.'.concat(error.codiError),
        message: error.missatge,
        icon: 'warning',
        showCancel: false,
        callback,
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: boolean }) => {
        this.dialog.closeAll();
      }
    );
  }

  async closeModal(save: boolean = false) {
    if (save) {
      this.data.data.alias = this.form.get('alias')?.value || '';
      if (this.data.data.alias === '') {
        return;
      }
      let response;
      this.data.data.favorit = false;
      switch (this.data.type) {
        case 'ruta':
          const poblacioNoValida = (pOrigen: string, pDesti: string) => {
            const dialogRef = this.dialog.open(ErrorModalComponent, {
                data: {
                  message: `Polació ${
                    !pOrigen ? 'origen' : 'destí'
                  } no vàlida`,
                  icon: 'warning',
                  showCancel: false,
                  title: 'Error de validació',
                },
              });
              dialogRef.componentInstance.closeEvent.subscribe(() => {
                this.dialog.closeAll();
              });
          }
          if(!this.data.data.poblacioOrigen || !this.data.data.poblacioDesti){
            poblacioNoValida(this.data.data.poblacioOrigen, this.data.data.poblacioDesti);
            return;
          }
          const origen = await lastValueFrom(this.contextService.getPoblacions(this.data.data.poblacioOrigen));
          const desti = await lastValueFrom(this.contextService.getPoblacions(this.data.data.poblacioDesti));
          const poblacioDesti = desti.find((el) => el.descripcio === this.data.data.poblacioDesti);
          const poblacioOrigen = origen.find((el) => el.descripcio === this.data.data.poblacioOrigen);
          if (!poblacioDesti || !poblacioOrigen) {
            poblacioNoValida(poblacioOrigen, poblacioDesti);
            return;
          }
          this.data.data.codiPoblacioOrigen = poblacioOrigen.codiElement;
          this.data.data.codiPoblacioDesti = poblacioDesti.codiElement;
          response = await lastValueFrom(
            this.rutaService.createRuta(this.data.data)
          );
          break;
        case 'transportista':
          response = await lastValueFrom(
            this.transportistaService.createTransportista(this.data.data)
          );
          break;
        case 'vehicle':
          response = await lastValueFrom(
            this.vehicleService.createVehicle(this.data.data)
          );
      }
      if (response?.validacioResultat) {
        this.errorValidator(response.validacioResultat[0]);
        return;
      }
    }
    this.dialog.closeAll();
  }
}
