import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = async () => {
  const empresa = JSON.parse(localStorage.getItem("empresa") || "{}");
  const authService = inject(AuthService);

  if(window.location.origin.includes("localhost")) {
    localStorage.setItem("jwt", "eyJlbmMiOiJBMTI4R0NNIiwiYWxnIjoiUlNBLU9BRVAtMjU2In0.gZ0P2lJje1Z2_2fN66W3CodjDKdQj5VnwAXXYWixhSLZDYcButk12FqGG1XveKIPS_R92FMfHWC-ZzFyRvkMvNZos5E6Sku3ahuNWLYa2vstAhVz_-C-HQ7-m5NnldevTdZ3-tiH7ESE9tKfo4xapAFElLHop5yDbUrGrnDlCckrnoTz8PP0CSyUKvqG2bgImlPKccPKCPlB1jyGzO8I3hxNzp9Qq995JZVT9RdU_v6k1rA_azqtTv58DwbjEoBpTnbM3E6kc8iGX8ssOVH_HWu5U0dpp798JvhMToFWDQzZwIg2mGkjm1xlkr0IsCK1prN126q-sRUvmmZ1DhMV_g.TDOveC3-2FOrov_q.inVjvM7YdBgMCtduW8bdpK9QgjgiPqRKi7GIQz6jXNcFz02gsqNhob13Pr5zPqf_yCfENUv-YiE7UYB8CM4F2mjoooBZxfUhrsGwVSLhA_lupqunDyT11KhN14iCxrRiSPInOXiAZfViigkYYpGhrPpnqgaQUyRqgu7wT46mNUl2bHFFfi7XdewrNuEKEKVw.g7fmE_bHAmXaNafF9YEN3A")
    localStorage.setItem("usuari", '{"empresaUsuariId":13,"dniUsuari":"00000000T","nifEmpresa":"23823172W","raoSocial":"Viewnext","nom":"Prova","cognom":"Usuari","secCognom":"UAT1","codiPerfil":"PROF_ADMIN","adrecaElectronica":"marti.sanchis@viewnext.com","telefon":"909090","registrat":true,"nomFitxer":null,"dataAcceptacio":null,"estat":true}' )
    localStorage.setItem("empresa", '{"empresaId":"1001","nif":"23823172W","raoSocial":"Viewnext","adreca":"Av. Aragó 28","codiPostal":"33444","poblacio":"València","codiPoblacio":"462508","adrecaElectronica":"djovel@viewnext.com","telefon":"654373829","rol":"CARRTRANSP"}' )
    localStorage.setItem("empresesUsuari", '[{"empresaUsuariId":13,"empresaId":"1001","nifEmpresa":"23823172W","raoSocial":"Viewnext","adrecaElectronica":"marti.sanchis@viewnext.com","telefon":"909090","seleccionat":true,"registrat":true,"codiPerfil":"PROF_ADMIN","rol":"CARRTRANSP","nomFitxer":null,"estat":true},{"empresaUsuariId":14,"empresaId":"7de2a30f-271b-4f55-b977-cdff36fcbcaa","nifEmpresa":"81085807C","raoSocial":"Carregadors Besòs","adrecaElectronica":"email@gencat.cat","telefon":"2222222222","seleccionat":false,"registrat":true,"codiPerfil":"PROF_ADMIN","rol":"CARRTRANSP","nomFitxer":null,"estat":true},{"empresaUsuariId":309,"empresaId":"1f37f584-6f5f-4fde-942d-d9dbd67f9c17","nifEmpresa":"PPP","raoSocial":null,"adrecaElectronica":"","telefon":"","seleccionat":false,"registrat":false,"codiPerfil":"PROF_ADMIN","rol":"CARREGADOR","nomFitxer":null,"estat":true}]')
    
    // BD LOCAL
    // localStorage.setItem('usuari', '{"empresaUsuariId": 2, "dniUsuari": "00000000T","nifEmpresa": "963963963","nom": "Prof N","cognom": "Prof C","secCognom": "Prof S","codiPerfil": "PROF_ADMIN","adrecaElectronica": "profesional@email.es","telefon": "333 666 998","registrat": true,"nomFitxer": null,"estat": true}')
    // localStorage.setItem("empresa", '{"empresaId": "eee-fff-ggg-hhh-999-666-333","nif": "963963963","raoSocial": "empresa 963","adreca": "calle 963","codiPostal": "963","poblacioId": null,"adrecaElectronica": "nueveseistres@mail.com","telefon": "963 963 963","rol": "CARRTRANSP"}')
    // localStorage.setItem("empresesUsuari", '[{"empresaUsuariId": 1,"nifEmpresa": "369369369","raoSocial": "empresa 369","seleccionat": false,"registrat": true,"codiPerfil": "PROF_GEST","rol": "CARREGADOR","nomFitxer": null,"estat": true},{"empresaUsuariId": 2,"nifEmpresa": "963963963", "raoSocial": "empresa 963","seleccionat": true,"registrat": true,"codiPerfil": "PROF_ADMIN","rol": "CARRTRANSP","nomFitxer": null,"estat": true}]')
    return true;
  }

  if(!empresa.nif) {
    return false;
  }
  if(window.location.href.includes("carregador") && empresa.rol === "TRANSPORTISTA") {
    window.location.href = "./#/inici";
  }
  if(window.location.href.includes("transportista")  && empresa.rol === "CARREGADOR") {
    window.location.href = "./#/inici";
  }

  const loged = await authService.isAuthenticated()
  if(!loged) {
    return false;
  }
  const registrat = authService.isRegistered()
  if(!registrat){
    window.location.href = "./#/registre";
    return false;
  }
  
  return true;
}
