<app-toast></app-toast>
<main>
  <app-sidebar [admin]="true"></app-sidebar>
  <section>
    <div id="content" [ngClass]="validat ? '' : 'noValidat'">
      @if(validat) {
      <div class="seccio">
        <div class="data_realitzacio">
          {{ "DC.DATA_R" | translate }}: {{ " " }} {{ dades.dataRealitzacio }}
        </div>
        <div class="cap-prev">
          <div>
            <h3 class="title3">{{ "DC.TITLE_CREAT" | translate }}</h3>
            <p>{{ "DC.SUBTITLE_CREAT" | translate }}</p>
            <br/>
          </div>
          <div class="codiCopiar">
            <label
              >{{ "DC.LABEL_CODI" | translate }}:{{ " "
              }}<span class="negreta">{{ dades.codiStmc }}</span></label
            >
            <p class="red dflex" (click)="copyCode(dades.codiStmc || '')">
              @if(copied) {
              <mat-icon>check</mat-icon><span class="copiat">{{ "COPIAT" | translate }}</span>
              }@else {
              <mat-icon>content_copy</mat-icon>
              }
            </p>
          </div>
        </div>
      </div>
      } @else {
      <div class="alerta seccio">
        <mat-icon>warning</mat-icon>
        <p>{{ "DC.ALERTA" | translate }}</p>
      </div>
      <div class="data_realitzacio">
        {{ "DC.DATA_R" | translate }}: {{ " " }} {{ dades.dataRealitzacio }}
      </div>
      <div class="seccio">
        <div class="cap-prev">
          <div>
            <h3 class="title3">{{ "DC.TITLE" | translate }}</h3>
          </div>
          <div class="codiCopiar">
            <label
              >{{ "DC.LABEL_CODI" | translate }}:{{ " "
              }}<span class="negreta">{{ dades.codiStmc }}</span></label
            >
            <p class="red dflex" (click)="copyCode(dades.codiStmc || '')">
              @if(copied) {
              <mat-icon>check</mat-icon><span class="copiat">{{ "COPIAT" | translate }}</span>
              }@else {
              <mat-icon>content_copy</mat-icon>
              }
            </p>
          </div>
        </div>
      </div>
      }
      <h4 class="title4">
        <mat-icon>forklift</mat-icon>
        <span>{{ "DC.DADES_CARREGADOR" | translate }}</span>
      </h4>
      <div class="seccio">
        <div class="row">
          <div class="col">
            <label for="nifEmpresa"
              >{{ "DC.RAO_SOCIAL_LABEL" | translate }}:</label
            >
            <p>{{ dades.carregadorContractual.raoSocial }}</p>
          </div>
          <div class="col">
            <label for="nifEmpresa">{{ "DC.NIF_LABEL" | translate }}:</label>
            <p>{{ dades.carregadorContractual.nif }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="nifEmpresa">{{ "DC.ADRECA_LABEL" | translate }}:</label>
            <p>{{ dades.carregadorContractual.adreca }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="nifEmpresa"
              >{{ "DC.POBLACIO_LABEL" | translate }}:</label
            >
            <p>{{ dades.carregadorContractual.poblacio }}</p>
          </div>
          <div class="col">
            <label for="nifEmpresa"
              >{{ "DC.CODI_POSTAL_LABEL" | translate }}:</label
            >
            <p>{{ dades.carregadorContractual.codiPostal }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="DE">{{ "DC.OBSERVACIONS" | translate }}:</label>
            <p>{{ dades.carregadorContractual.observacions }}</p>
          </div>
        </div>
      </div>
      <h4 class="title4">
        <mat-icon>local_shipping</mat-icon>
        <span>{{ "DC.DADES_TRANSPORTISTA" | translate }}</span>
      </h4>
      <div class="seccio">
        <div class="row">
          <div class="col">
            <label for="nifEmpresa"
              >{{ "DC.RAO_SOCIAL_LABEL" | translate }}:</label
            >
            <p>{{ dades.transportistaEfectiu.raoSocial }}</p>
          </div>
          <div class="col">
            <label for="nifEmpresa">{{ "DC.NIF_LABEL" | translate }}:</label>
            <p>{{ dades.transportistaEfectiu.nif }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="DE">{{ "DC.OBSERVACIONS" | translate }}:</label>
            <p>{{ dades.transportistaEfectiu.observacions }}</p>
          </div>
        </div>

        <h4 class="title4">
          <mat-icon>location_on</mat-icon>
          <span>{{ "DC.DADES_RUTA" | translate }}</span>
        </h4>
        <div class="seccio">
          <div class="row">
            <div class="col">
              <label for="nifEmpresa"
                >{{ "DC.ORIGEN_LABEL" | translate }}:</label
              >
              <p>
                {{ dades.rutaStmc.poblacioOrigen }}
                @if(dades.rutaStmc.provinciaOrigen) {
                ({{ dades.rutaStmc.provinciaOrigen }})
                }
              </p>
            </div>
            <div class="col">
              <label for="nifEmpresa"
                >{{ "DC.DESTI_LABEL" | translate }}:</label
              >
              <p>
                {{ dades.rutaStmc.poblacioDesti }}
                @if(dades.rutaStmc.provinciaDesti) {
                  ({{ dades.rutaStmc.provinciaDesti }})
                }
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label for="nifEmpresa"
                >{{ "DC.ADRECA_ORIGEN_LABEL" | translate }}:</label
              >
              <p>{{ dades.rutaStmc.adrecaOrigen }}</p>
            </div>
            <div class="col">
              <label for="nifEmpresa"
                >{{ "DC.ADRECA_DESTI_LABEL" | translate }}:</label
              >
              <p>{{ dades.rutaStmc.adrecaDesti }}</p>
            </div>
          </div>
        </div>

        <h4 class="title4">
          <mat-icon>directions_car</mat-icon>
          <span>{{ "DC.DADES_VEHICLE" | translate }}</span>
        </h4>
        <div class="seccio">
          <div class="row">
            <div class="col">
              <label 
                >{{ "DC.MATRICULA_VEHICLE_LABEL" | translate }}:</label
              >
              <p>{{ dades.mitjaTransport.matriculaTractor }}</p>
            </div>
            <div class="col">
              <label 
                >{{ "DC.MATRICULA_REMOLC_LABEL" | translate }}:</label
              >
              <p>{{ dades.mitjaTransport.matriculaRemolc }}</p>
            </div>
          </div>

          <div class="row">
            @if(dades.mitjaTransport.matriculaTractorSubs1 || dades.mitjaTransport.matriculaTractorSubs2) {
            <div class="col">
              <label 
                >{{ "DC.MATRICULA_VEHICLE_LABEL_SUB" | translate }}:</label
              >
              <p>{{ dades.mitjaTransport.matriculaTractorSubs1 }}</p>
              <p>{{ dades.mitjaTransport.matriculaTractorSubs2 }}</p>
            </div>
            }
            @if(dades.mitjaTransport.matriculaRemolcSubs1 || dades.mitjaTransport.matriculaRemolcSubs2){
            <div class="col">
              <label 
                >{{ "DC.MATRICULA_REMOLC_LABEL_SUB" | translate }}:</label
              >
              <p>{{ dades.mitjaTransport.matriculaRemolcSubs1 }}</p>
              <p>{{ dades.mitjaTransport.matriculaRemolcSubs2 }}</p>
            </div>
            }
          </div>
        </div>

        <h4 class="title4">
          <mat-icon>inventory_2</mat-icon>
          <span>{{ "DC.DADES_MERCADERIA" | translate }}</span>
        </h4>
        <div class="seccio">
          <div class="row">
            <div class="col">
              <label for="nifEmpresa"
                >{{ "DC.TIPUS_LABEL" | translate }}:</label
              >
              @if(mercaderies.length){
              <p>{{ mercaderies[0].descripcio }}</p>
              }
            </div>
            <div class="col">
              <label for="nifEmpresa">{{ "DC.PES_LABEL" | translate }}:</label>
              <p>{{ dades.mercaderiaStmc.pes }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label for="nifEmpresa"
                >{{ "DC.AUTORITZACIO_LABEL" | translate }}:</label
              >
              <p>
                {{ dades.mercaderiaStmc.requereixAutoritzacio ? "Sí" : "No" }}
              </p>
            </div>
            <div class="col">
              <label for="nifEmpresa"
                >{{ "DC.NUM_AUT_LABEL" | translate }}:</label
              >
              <p>{{ dades.mercaderiaStmc.numeroAutorizacio }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label for="descripcioMercaderia"
                >{{ "DC.DESCRIPCIO_MERCADERIA_LABEL" | translate }}:</label
              >
              <p>{{ dades.mercaderiaStmc.descripcioMercaderia }}</p>
            </div>
          </div>
        </div>

        <div class="botons">
          <button class="tornar" (click)="tornar()">
            <mat-icon>arrow_back_ios</mat-icon>
            {{ "DC.TORNAR" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="side-column">
    @if(validat) {
    <div class="saveBox">
      <div class="botons-dowload">
        <button class="ctti-button ctti-button-filled" (click)="email()">
          <mat-icon>send</mat-icon>
          {{ "DC.EMAIL" | translate }}
        </button>
        <button class="tornar" (click)="pdf()">
          <mat-icon>download</mat-icon>
          {{ "DC.PDF" | translate }}
        </button>
      </div>
    </div>
    } @else { @if(dades.canviDepresDc != false && dades.estatStmc === "PLANIFICAT") {
    <div class="saveBox">
      <button class="validar" (click)="validar()">
        <mat-icon>check_circle</mat-icon>
        {{ "DC.VALIDAR" | translate }}
      </button>
    </div>
    } }
    <div class="seccions">
      <h5 class="title5">{{ "STMC.SECTIONS" | translate }}</h5>
      <div class="sections">
        <div class="lane">
          <div
            class="bola"
            [ngClass]="{validated: isTabValid(1),}"
          >
            @if(isTabValid(1)) {
            <mat-icon>check</mat-icon>
            }
          </div>
          <p>{{ "STMC.SECTION1" | translate }}</p>
        </div>
        <div class="lane">
          <div
            class="bola"
            [ngClass]="{validated: isTabValid(2)}"
          >
            @if(isTabValid(2)) {
            <mat-icon>check</mat-icon>
            }
          </div>
          <p>{{ "STMC.SECTION2" | translate }}</p>
        </div>
        <div class="lane">
          <div
            class="bola"
            [ngClass]="{validated: isTabValid(3),}"
          >
            @if(isTabValid(3)) {
            <mat-icon>check</mat-icon>
            }
          </div>
          <p>{{ "STMC.SECTION3" | translate }}</p>
        </div>
        <div class="lane">
          <div
            class="bola"
            [ngClass]="{validated: isTabValid(4),}"
          >
            @if(isTabValid(4)) {
            <mat-icon>check</mat-icon>
            }
          </div>
          <p>{{ "STMC.SECTION4" | translate }}</p>
        </div>
        <div class="lane">
          <div
            class="bola"
            [ngClass]="{validated: isTabValid(5),}"
          >
            @if(isTabValid(5)) {
            <mat-icon>check</mat-icon>
            }
          </div>
          <p>{{ "STMC.SECTION5" | translate }}</p>
        </div>
      </div>
    </div>
    </div>
  </section>
</main>
