<app-toast></app-toast>
<main>
  <app-sidebar [admin]="true"></app-sidebar>
  <section>
    <div class="block">
      <div class="mb">
        <h1>{{ "CARREGA.TITLE" | translate }}</h1>
        <p class="sub">{{ "CARREGA.SUBTITLE" | translate }}</p>
      </div>
    </div>
    <div class="block">
      <input
        type="file"
        id="fileInput"
        style="display: none"
        (change)="handleFileInput($event)"
        accept=".csv"
      />
      <div class="dades" [formGroup]="formData">
        <h5>{{ "CARREGA.CERCA" | translate }}</h5>
        <div class="filtres">
          <div class="grid-inputs">
            <div class="input">
              <label>{{ "CARREGA.NOM_FITXER" | translate }}:</label>
              <input
                matInput
                type="text"
                id="nomFitxer"
                formControlName="nomFitxer"
                placeholder="{{ 'CARREGA.NPM_FITXER_PLACEHOLDER' | translate }}"
                class="ctti-input-text"
              />
            </div>
            <div class="input">
              <label>{{ "CARREGA.DATES" | translate }}:</label>
              <div class="input-dates">
                <input
                  type="date"
                  class="ctti-input-text"
                  [class]="formData.value.dataCarregaInici ? 'enDada' : ''"
                  formControlName="dataCarregaInici"
                  placeholder="dd/mm/aaaa"
                />
                <input
                  type="date"
                  class="ctti-input-text"
                  [class]="formData.value.dataCarregaFi ? 'enDada' : ''"
                  formControlName="dataCarregaFi"
                  placeholder="dd/mm/aaaa"
                />
              </div>
            </div>
            <div class="input">
              <div class="input-dates">
                <div class="input">
                  <label>{{ "CARREGA.CODI" | translate }}:</label>
                  <input
                    matInput
                    type="text"
                    id="codiStmc"
                    formControlName="codiStmc"
                    placeholder="{{ 'CARREGA.CODI_PLACEHOLDER' | translate }}"
                    class="ctti-input-text"
                  />
                </div>
                <div class="input">
                  <label>{{ "CARREGA.RESULTAT" | translate }}:</label>
                  <mat-select
                    formControlName="resultat"
                    class="ctti-dropdown desplegable"
                    [ngClass]="formData.value.estat ? 'enDada' : ''"
                  >
                    <mat-option value="" selected>
                      {{ "CARREGA.RESULTAT_PLACEHOLDER" | translate }}
                    </mat-option>
                    <mat-option value="FALLO">{{
                      "CARREGA.ERRORS" | translate
                    }}</mat-option>
                    <mat-option value="VALID">
                      {{ "CARREGA.VALIDS" | translate }}
                    </mat-option>
                    <mat-option value="AVIS">
                      {{ "CARREGA.AVISOS" | translate }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
            <div class="botons-filtres">
              <div class="accions-filtres">
                <button
                  class="ctti-button ctti-button-trans"
                  (click)="clearFilter()"
                >
                  {{ "CARREGA.CLEAR" | translate }}
                </button>
                <button
                  class="ctti-button ctti-button-filled"
                  (click)="applyFilter()"
                >
                  <mat-icon>search</mat-icon>{{ "CARREGA.SEARCH" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
        @if(potCarregar) {
        <div class="crear tablet">
          <h5>{{ "CARREGA.STMC_NEW" | translate }}</h5>
          <div class="saveBox">
            <button
              class="desar ctti-button ctti-button-filled"
              (click)="novaCarrega()"
            >
              <mat-icon>note_add</mat-icon>
              {{ "CARREGA.STMC_NEW_BTN" | translate }}
            </button>
          </div>
        </div>
      }
        <h5>{{ "CARREGA.RESULTATS" | translate }}</h5>
        <div class="stmcs">
          <div class="order">
            <mat-select
              [value]="sortField"
              class="ctti-dropdown desplegable enDada"
              (change)="sortFieldChange($event)"
            >
              <mat-option
                value="dataCarrega"
                (click)="updateSortField('dataCarrega')"
              >
                {{ "CARREGA.DATA_CARREGA_ORD" | translate }}
              </mat-option>
              <mat-option
                value="nomFitxer"
                (click)="updateSortField('nomFitxer')"
              >
                {{ "CARREGA.NOM_FITXER_ORD" | translate }}
              </mat-option>
            </mat-select>
            <mat-icon (click)="changeOrder()">{{
              sortOrder ? "arrow_upward" : "arrow_downward"
            }}</mat-icon>
          </div>
          @if(dataSource && dataSource.length) {
          <table>
            <thead>
              <tr>
                <th class="invs">.</th>
                <th class="invs">.</th>
              </tr> 
            </thead>
            <tbody>
              <tr *ngFor="let element of dataSource" class="stmc-row">
                <td class="dadesFitxer">
                  <div class="nomFitxer">
                    {{ saltLinia(element.nomFitxer || "") }}
                  </div>
                  <div class="data">
                    <mat-icon>calendar_today</mat-icon
                    >{{ element.dataCarrega ? element.dataCarrega.replaceAll("-", "/") : "" }}
                  </div>
                  <div class="accions">
                    <p class="red" (click)="viewElement(element)">
                      <mat-icon>visibility</mat-icon>
                    </p>
                    <div class="botons">
                      <button (click)="fitxerResultat(element.objectMantId)">
                        <mat-icon>download</mat-icon>
                        {{ "CARREGA.FITXER_RESULTAT" | translate }}
                      </button>
                      <button (click)="dcs(element.objectMantId)">
                        <mat-icon>download</mat-icon>
                        {{ "CARREGA.DCS" | translate }}
                      </button>
                    </div>
                  </div>
                </td>
                <td class="resultatsFitxer">
                  @if(element.liniesValids	> 0) {
                  <div class="valid">
                    <mat-icon>verified</mat-icon>
                    {{ "CARREGA.VALIDS" | translate }}:
                    {{ element.liniesValids }}
                  </div>
                  } @if(element.liniesAmbErrors > 0) {
                  <div class="errors">
                    <mat-icon>warning_amber</mat-icon>
                    {{ "CARREGA.ERRORS" | translate }}:
                    {{ element.liniesAmbErrors }}
                  </div>
                  } @if(element.liniesAmbAlertes > 0) {
                  <div class="avisos">
                    <mat-icon>report</mat-icon>
                    {{ "CARREGA.AVISOS" | translate }}:
                    {{ element.liniesAmbAlertes }}
                  </div>
                  } @if(element.nombreStmcsEsborranys > 0) {
                  <div class="esborranys">
                    <span>|</span>
                    <mat-icon>find_in_page</mat-icon>
                    {{ "CARREGA.ESBORRANY" | translate }}:
                    {{ element.nombreStmcsEsborranys }}
                  </div>
                  }
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-controls">
            <div class="items-page">
              <label for="itemsPerPage" class="invs">.</label>
              <select
                id="itemsPerPage"
                (change)="onItemsPerPageChange($event)"
                [value]="itemsPerPage"
                #itemsPerPageDrop
              >
                <option *ngFor="let size of pageSizeOptions" [value]="size">
                  {{ size }} / {{ "CARREGA.PAG" | translate }}
                </option>
              </select>
              <p class="paginacio">
                {{ "CARREGA.DE" | translate }} {{ totalRegistres }}
                {{ "CARREGA.ELEMENTS" | translate }}
              </p>
            </div>
            <div class="page-number">
              <label for="currentPageDrop" class="invs">.</label>
              <select
                [value]="currentPage"
                (change)="goToPage($event)"
                #currentPageDrop
                id="currentPageDrop"
              >
                <option *ngFor="let index of pageNumberArr" [value]="index">
                  {{ index }}
                </option>
              </select>
              <p class="paginacio">
                {{ "CARREGA.DE" | translate }} {{ totalPages }}
                {{ "CARREGA.PAGINES" | translate }}
              </p>
              @if(currentPage !== 1) {
              <button (click)="previousPage()">
                <mat-icon>chevron_left</mat-icon>
              </button>
              } @if(currentPage !== totalPages) {
              <button (click)="nextPage()">
                <mat-icon>chevron_right</mat-icon>
              </button>
              }
            </div>
          </div>
          } @else {
          <app-senseresultats></app-senseresultats>
          }
        </div>

        <div class="estats">
          <div class="estats-drop" (click)="showEstats()">
            <h5>{{ "CARREGA.LLEGENDA" | translate }}</h5>
            <div>
              @if(mostrarEstats) {
              <mat-icon>expand_less</mat-icon>
              } @else {
              <mat-icon>expand_more</mat-icon>
              }
            </div>
          </div>
          @if(mostrarEstats){
          <div class="llista-estats">
            <div>
              <div class="icona-info valid">
                <mat-icon>verified</mat-icon>
                {{ "CARREGA.VALIDS" | translate }}
              </div>
              <p>{{ "CARREGA.VALIDS_DESC" | translate }}</p>
            </div>
            <div>
              <div class="icona-info errors">
                <mat-icon>warning_amber</mat-icon>
                {{ "CARREGA.ERRORS" | translate }}
              </div>
              <p>{{ "CARREGA.ERRORS_DESC" | translate }}</p>
            </div>
            <div>
              <div class="icona-info avisos">
                <mat-icon>report</mat-icon>
                {{ "CARREGA.AVISOS" | translate }}
              </div>
              <p>{{ "CARREGA.AVISOS_DESC" | translate }}</p>
            </div>
            <div>
              <div class="icona-info esborranys">
                <mat-icon>find_in_page</mat-icon>
                {{ "CARREGA.ESBORRANY" | translate }}
              </div>
              <p>{{ "CARREGA.ESBORRANY_DESC" | translate }}</p>
            </div>
          </div>
          }
        </div>
      </div>
      <div class="crear desktop">
        <h5>{{ "CARREGA.STMC_NEW" | translate }}</h5>
        <div class="saveBox">
          <button
            class="desar ctti-button ctti-button-filled"
            (click)="novaCarrega()"
          >
            <mat-icon>note_add</mat-icon>
            {{ "CARREGA.STMC_NEW_BTN" | translate }}
          </button>
        </div>
      </div>
    </div>
  </section>
</main>
