import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Importación de ngx-translate
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider'; 
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator'; 
import { MatAutocompleteModule } from '@angular/material/autocomplete'; 
import { MatTableModule } from '@angular/material/table'; 

import { ValidComponent } from './pages/valid/valid.component';
import { RegistreComponent } from './pages/registre/registre.component';
import { IniciComponent } from './pages/inici/inici.component';
import { TransportistaComponent } from './pages/transportista/transportista.component';
import { CarregadorComponent } from './pages/carregador/carregador.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { AdministracioComponent } from './pages/administracio/administracio.component';
import { StmcComponent } from './pages/stmc/stmc.component';
import { ErrorComponent } from './pages/error/error.component';
import { DcComponent } from './pages/dc/dc.component';

import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { ErrorModalComponent } from './shared/error-modal/error-modal.component';
import { ViewStmcComponent } from './shared/view-stmc/view-stmc.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { ToastComponent } from './shared/toast/toast.component';
import { MatriculesComponent } from './shared/matricules/matricules.component';
import { EmailDcComponent } from './shared/email/email-dc.component';
import { HabitualsListComponent } from './shared/habituals/habitualsList.component';
import { HabitualsEditComponent } from './shared/habituals/habitualsEdit.component';
import { HabitualsNewComponent } from './shared/habituals/habitualsNew.component';
import { UserEditComponent } from './shared/user/user.component';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';



import { AuthInterceptor } from './services/auth.interceptor';
import { UsuariService } from './services/api/usuari.service';
import { RutaService } from './services/api/ruta.service';
import { ContextService } from './services/api/context.service';
import { StmcService } from './services/api/stmc.service';

import { LayoutModule } from '@angular/cdk/layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidepanelComponent } from './shared/sidepanel/sidepanel.component';

@NgModule({
  declarations: [
    AppComponent,
    UserEditComponent,
    RegistreComponent,
    IniciComponent,
    FooterComponent,
    HeaderComponent,
    ValidComponent,
    ErrorModalComponent,
    SidebarComponent,
    ViewStmcComponent,
    TransportistaComponent,
    CarregadorComponent,
    PerfilComponent,
    AdministracioComponent,
    StmcComponent,
    ToastComponent,
    HabitualsEditComponent,
    HabitualsListComponent,
    HabitualsNewComponent,
    ErrorComponent,
    DcComponent,
    MatriculesComponent,
    EmailDcComponent,
    SidepanelComponent
  ],
  imports: [
    LayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    MatDividerModule, 
    MatDatepickerModule,
    MatPaginatorModule, 
    HttpClientModule,
    MatAutocompleteModule, 
    MatTableModule,
    MatTooltipModule,
    MatSidenavModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    UsuariService,
    RutaService,
    ContextService,
    StmcService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// Necesario para la compilación AOT (Ahead Of Time)
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
