import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  isVisible = false;
  message = '';
  title = '';

  constructor() { }

  ngOnInit(): void {}

  open(title: string = "", message: string, duration: number = 3000): void {
    this.message = message;
    this.title = title;
    this.isVisible = true;
    setTimeout(() => this.close(), duration);
  }

  close(): void {
    this.isVisible = false;
  }
}
