import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { EmpresaUsuari } from '../types/EmpresaUsuari';
import { ValidacioResultat } from '../types/ValidacioResultat';
import { Empresa } from '../types/Empresa';
import { Usuari } from '../types/Usuari';
import { UsuariService } from './api/usuari.service';
import { lastValueFrom, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { TokenResponse } from '../types/TokenResponse';

interface AuthResponse {
  authError: string;
  validacioResultat: ValidacioResultat[];
  accessToken: string;
  refreshToken: string;
  usuari: Usuari;
  empresa: Empresa;
  empresesUsuari: EmpresaUsuari[];
}
interface TokenEmpresaResponse {
  authError: string | null;
  accessToken: string;
  refreshToken: string;
  usuari: Usuari;
  empresa: Empresa;
  empresesUsuari: EmpresaUsuari[];
  validacioResultat: any | null;
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl: string = environment.apiUrl+"auth/";
  service = inject(UsuariService);

  private userLogin = new Subject<null>;
  userLogin$ = this.userLogin.asObservable();
  
  constructor(private http: HttpClient) {}

  emitUserLogin() {
    this.userLogin.next(null);
  }

  noAuth = () => {
    localStorage.removeItem('usuari');
    localStorage.removeItem('empresesUsuari');
    localStorage.removeItem('empresa');
    localStorage.removeItem('jwt');
    localStorage.removeItem('uuid');
    localStorage.removeItem('refresh');
    const uuid1 = uuidv4();
    const uuid2 = uuidv4();
    localStorage.setItem('uuid', uuid1);
    setTimeout(()=> window.location.href = `${environment.validUrl}state=${uuid1}&response_type=code&scope=openid&nonce=${uuid2}&client_secret=${environment.clientSecret}`, 5000)
    return false;
  };

  async isAuthenticated() {
    
    const refresh = localStorage.getItem('refresh') || "";
    if (!refresh) {
      console.log("No refresh")
      return this.noAuth();
    }
    try {
      const data: TokenResponse = await lastValueFrom(this.refreshToken(refresh));
      if(data.access_token) {
        localStorage.setItem('jwt', data.access_token);
        return true;
      } 
      console.log("No acces token")
      return this.noAuth();
    } catch(err) {
      console.log("Error refresh token", err)
      return this.noAuth();
    }
  }

  login(uuid: string) {
    return this.http.get<AuthResponse>(`${this.apiUrl}dades/login?estatSesion=${uuid}`);
  }
  
  refreshToken(rToken: string) {
    if(!rToken) { 
      this.noAuth();
    }
    return this.http.get<TokenResponse>(`${this.apiUrl}regenerar/token?refreshToken=${rToken}`);
  }

  refreshTokenEmpresa(rToken: string, empresaUsuariId: number) {
    if(!rToken) { 
      this.noAuth();
    }
    return this.http.put<TokenEmpresaResponse>(`${environment.apiUrl}professional/${empresaUsuariId}/seleccionarEmpresa?refreshToken=${rToken}`, {});
  }

  isRegistered() {
    const data = localStorage.getItem("usuari");
    if(data) {
      const decoded = JSON.parse(data);
      if(!decoded.registrat) {
        return false;
      }
      if(!decoded.adrecaElectronica){
        return false;
      }
      return true;
    }
    return false;
  }

  logout(dni: string) {
    const refresh = localStorage.getItem("refresh")
    const url = `${this.apiUrl}${dni}/logout?refreshToken=${refresh}`;
    return this.http.get<any[]>(url);
  }
  
}
