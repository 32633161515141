import { Component, inject } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-inici',
  templateUrl: './valid.component.html',
  styleUrl: './valid.component.scss',
})
export class ValidComponent {
  service = inject(AuthService);

  async ngOnInit() {
    await this.checkLogin();
  }

  redirectValid() {
    const uuid1 = uuidv4();
    const uuid2 = uuidv4();
    localStorage.setItem('uuid', uuid1);
    window.location.href = `${environment.validUrl}state=${uuid1}&response_type=code&scope=openid&nonce=${uuid2}&client_secret=${environment.clientSecret}`;
  }

  async checkLogin() {
    const token = localStorage.getItem('refresh');
    const uuid = localStorage.getItem('uuid') || "";
    if (!token) {
      if (uuid) {
        try {
          const loginData = await lastValueFrom(this.service.login(uuid));
          localStorage.setItem('jwt', loginData.accessToken);
          localStorage.setItem('refresh', loginData.refreshToken);
          localStorage.setItem('usuari', JSON.stringify(loginData.usuari));
          localStorage.setItem('empresesUsuari', JSON.stringify(loginData.empresesUsuari));
          localStorage.setItem('empresa', JSON.stringify(loginData.empresa));
          this.service.emitUserLogin()
          window.location.href = './#/inici';
          return;
        } catch (err) {
          localStorage.removeItem('usuari');
          localStorage.removeItem('empresesUsuari');
          localStorage.removeItem('empresa');
          localStorage.removeItem('jwt');
          localStorage.removeItem('refresh');
          localStorage.removeItem('uuid');
          console.log("Error en llamada login")
          setTimeout(() => this.redirectValid(), 2500);
        }
      }
      console.log("No token No uuiod")
      setTimeout(() => this.redirectValid(), 2500);
      return;
    }
    console.log("Token -> inici")
    setTimeout(() => window.location.href = './#/inici', 2500);
    return;
  }
}
