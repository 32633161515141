import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Vehicle } from '../../types/Vehicle';

interface ResponseMultiple {
  dadesResultat: Vehicle[]
  validacioResultat: any,
  dadesPaginacio: any
}
interface ResponseSingle {
  dadesResultat: Vehicle
  validacioResultat: any
}

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  private apiUrl: string = environment.apiUrl+"vehicle";
  
  constructor(private http: HttpClient) {}

  createVehicle(Vehicle: Vehicle): Observable<ResponseSingle> {
    return this.http.post<ResponseSingle>(this.apiUrl, Vehicle);
  }

  getVehicleById(id: string): Observable<ResponseSingle> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<ResponseSingle>(url);
  }

  vehicleFindBy(filters: any): Observable<ResponseMultiple> {
    const url = `${this.apiUrl}/findBy`;
    return this.http.post<ResponseMultiple>(url, filters);
  }

  updateVehicle(Vehicle: Vehicle): Observable<ResponseSingle> {
    const url = `${this.apiUrl}/${Vehicle.objectMantId}`;
    return this.http.put<ResponseSingle>(url, Vehicle);
  }

  deleteVehicle(id: number): Observable<void> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete<void>(url);
  }

 
}
