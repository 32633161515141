<footer>
       <div class="imgs-footer">
          <span class="img-footer"
             ><img src="../../../assets/images/UE.png" alt=""
             /></span>
          <span class="img-footer"
             ><img src="../../../assets/images/Gobierno de España.png" alt=""
             /></span>
         <span class="img-footer"
            ><img src="../../../assets/images/Next Generation Catalunya.png" alt=""
            /></span>
         <span class="img-footer"
            ><img src="../../../assets/images/Logo Gene.png" alt=""
            /></span>
       </div>
 </footer>