import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Stmc } from '../../types/Stmc';
import { Router } from '@angular/router';
import { ViewStmcComponent } from '../../shared/view-stmc/view-stmc.component';
import { MatDialog } from '@angular/material/dialog';
import { StmcService } from '../../services/api/stmc.service';
import { lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastComponent } from '../../shared/toast/toast.component';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal.component';
import { ContextService } from '../../services/api/context.service';

@Component({
  selector: 'app-carregador',
  templateUrl: './carregador.component.html',
  styleUrl: './carregador.component.scss',
})
export class CarregadorComponent {
  @ViewChild(ToastComponent) toast!: ToastComponent;
  @ViewChild('currentPageDrop') currentPageDrop!: ElementRef<HTMLSelectElement>;
  @ViewChild('itemsPerPageDrop') itemsPerPageDrop!: ElementRef<HTMLSelectElement>;
  translate = inject(TranslateService)
  contextService = inject(ContextService);
  stmcService = inject(StmcService);
  tooltipText: string = "";
  totalRegistres: number = 0;
  dataSource: Stmc[] = [];
  paginatedData: Stmc[] = [];
  pageNumberArr: number[] = [];
  pageSizeOptions: number[] = [5, 10, 20];
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalPages: number = 1;
  mostrarEstats: boolean = false;
  mesFiltres: boolean = false;
  tipusSelected: number = 1;
  poblacions: any[] = [];
  mercaderies: any[] = []
  estats = [
    'ESBORRANY',
    'PLANIFICAT',
    'REALIZAT',
    'NO_REALIZAT',
    'REBUTJAT',
    'CANCELAT',
];
estatsDc = ['DC_CREAT', 'DC_NO_CREAT', 'DC_MODIFICACIO'];
  estatsSelected: string[] = this.estats;
  estatsDcSelected: string[] = this.estatsDc;
  sortField: string = 'estatStmc';
  sortOrder: boolean = true;
  formData: FormGroup = this.fb.group({
    codiStmc: [''],
    nifRaoTransportista: [''],
    dataRealitzacioInici: [''],
    dataRealitzacioFi: [''],
    matriculaVehicle: [''],
    tipusMercaderia: [''],
    poblacioOrigen: [''],
    poblacioDesti: [''],
    estatStmc: [[]],
    estatDc: [[]]
  });
  isFocused = { poblacioOrigen: false, poblacioDesti: false };
  codiPoblacioOrigen = ""
  codiPoblacioDesti = ""

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.translate.get('CARREGADOR.SUBTITLE').subscribe((res: string) => {
      this.tooltipText = res;
    });
  }

  async ngOnInit() {
    const recuperar = localStorage.getItem('recuperar');
    const filtres = localStorage.getItem('filtres') || "{}";
    const ordenacio = localStorage.getItem('ordenacio');
    const itemsPerPage = localStorage.getItem('itemsPerPage') || "5";
    const currentPage = localStorage.getItem('currentPage') || "1";
    const totalPages = localStorage.getItem('totalPages') || "1";
    if(recuperar) {
      this.pageNumberArr = Array.from({ length: parseInt(totalPages) },(_, i) => i + 1);
      this.itemsPerPage = parseInt(itemsPerPage);
      this.currentPage = parseInt(currentPage);
      if(ordenacio) {
        this.sortField = ordenacio
      }
      const filtresConsulta = JSON.parse(filtres);
      const parseData = (data: string) => {
        const date = data.split('-');
        let newDate = date[0].length !== 4
          ? `${date[2]}-${date[1]}-${date[0]}`
          : `${date[0]}-${date[1]}-${date[2]}`;
        return newDate;
      } 
      if(filtresConsulta.dataRealitzacioFi) {
        filtresConsulta.dataRealitzacioFi = parseData(filtresConsulta.dataRealitzacioFi.split(" ")[0])
      }
      if(filtresConsulta.dataRealitzacioInici) {
        filtresConsulta.dataRealitzacioInici = parseData(filtresConsulta.dataRealitzacioInici.split(" ")[0])
      }
      await this.fetchData(JSON.parse(filtres));
      this.formData.patchValue(filtresConsulta)
      localStorage.setItem('filtres', '{}');
      localStorage.setItem('recuperar', '');
      localStorage.setItem('ordenacio', '');
      localStorage.setItem('currentPage', '');
      localStorage.setItem('itemsPerPage', '');
      localStorage.setItem('totalPages', '');
    } else {
      await this.fetchData();
    }
    this.mercaderies = (await lastValueFrom(this.contextService.getTotsMercaderies())).map(el => {
      if(el.descripcio.length > 47){
        return {...el, descripcio: el.descripcio.substring(0, 47).concat("...")};
      }
      return el;
    });
  }

  async fetchData(filters: any = {}) {
    if( this.currentPage == 0) {
      this.currentPage = 1;
    }
    Object.keys(filters).forEach(key => {
      if (filters[key] === "") {
        delete filters[key];
      }
    });
    if(new Date(filters.dataRealitzacioInici) > new Date(filters.dataRealitzacioFi)) {
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: {
          title: `CARREGADOR.DATES_INVALIDES`,
          icon: 'warning',
          showCancel: true,
          message: '',
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(
        async (result: { accept: boolean }) => {
          this.dialog.closeAll();
        }
      );
      return;
    }
    if(filters.dataRealitzacioFi) {
      const date = filters.dataRealitzacioFi.split("-")
      filters.dataRealitzacioFi = date[0].length === 4 ? `${date[2]}-${date[1]}-${date[0]}` : `${date[0]}-${date[1]}-${date[2]}`
    }
    if(filters.dataRealitzacioInici) {
      const date = filters.dataRealitzacioInici.split("-")
      filters.dataRealitzacioInici = date[0].length === 4 ? `${date[2]}-${date[1]}-${date[0]}` : `${date[0]}-${date[1]}-${date[2]}`
    }
    const data = await lastValueFrom(this.stmcService.stmcFindBy({
      "dadesPaginacio": {
        "numRegisters": this.itemsPerPage,
        "numPagina": this.currentPage,
        "ordreCamp": this.sortField,
        "ordreAsc": this.sortOrder
      },
      "filtresConsulta": {        
        ...filters, 
        estat: true,
        rolSeleccionat: "CARREGADOR"}
    }))
    this.dataSource = data.dadesResultat;
    this.totalRegistres = data.dadesPaginacio.totalRegisters
    this.totalPages = Math.ceil(
      this.totalRegistres / this.itemsPerPage
    );
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }
    this.pageNumberArr = Array.from(
      { length: this.totalPages },
      (_, i) => i + 1
    );
    this.currentPageDrop.nativeElement.value = this.currentPage.toString();
    this.itemsPerPageDrop.nativeElement.value = this.itemsPerPage.toString();
  }

  async applyFilter() {
    this.currentPage = 1;
    await this.fetchData(this.formData.value)
  }

  editElement(codi: string) {
    localStorage.setItem("filtres",JSON.stringify(this.formData.value))
    localStorage.setItem('ordenacio', this.sortField);
    localStorage.setItem('currentPage', this.currentPage.toString());
    localStorage.setItem('itemsPerPage', this.itemsPerPage.toString());
    localStorage.setItem('totalPages', this.totalPages.toString());
    this.router.navigate(['/carregador/stmc/'.concat(codi)]);
  }

  viewElement(element: Stmc) {
    const dialogRef = this.dialog.open(ViewStmcComponent, {
      data: { stmc: element, pag: 'carregador' },
    });
    dialogRef.componentInstance.closeEvent.subscribe(() => {
      this.dialog.closeAll();
    });
  }

  async duplicar(codiStmc: string) {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: { title: "MODAL.DUPLICAR", icon: "help", showCancel: true, message: "STMC ".concat(codiStmc) }
    });
    dialogRef.componentInstance.closeEvent.subscribe(async (result: {accept: true}) => {
      if(result.accept) {
        const duplicat = await lastValueFrom(this.stmcService.duplicar(codiStmc));
        if(duplicat.dadesResultat.codiStmc) {
          const dialogRef = this.dialog.open(ErrorModalComponent, {
            data: { title: "STMC duplicat amb codi ".concat(duplicat.dadesResultat.codiStmc), icon: "check", showCancel: false, message: "" }
          });
          dialogRef.componentInstance.closeEvent.subscribe(() => {
            this.dialog.closeAll();
          });
        }
        await this.fetchData(this.formData.value)
      } else {
        this.dialog.closeAll();
      }
    });
  }

  async cancelar(element: Stmc) {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: `Es va a cancelar l'STMC amb codi ${element.codiStmc}`,
        icon: 'warning',
        showCancel: true,
        message: '',
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: boolean }) => {
        if (result.accept) {
          await lastValueFrom(this.stmcService.canviarEstat(element, 'cancelar'));
          this.fetchData(this.formData.value);
        }
        this.dialog.closeAll();
      }
    );
  }

  async realitzar(element: Stmc) {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: `Es va a marcar com realitzat l'STMC amb codi ${element.codiStmc}`,
        icon: 'warning',
        showCancel: true,
        message: '',
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: boolean }) => {
        if (result.accept) {
          await lastValueFrom(this.stmcService.canviarEstat(element, 'realitzat'));
          this.fetchData(this.formData.value);
        }
        this.dialog.closeAll();
      }
    );
  }

  generarDC(element: Stmc) {
    if(element.estatDc === "DC_CREAT") {
      window.location.href = `./#/carregador/dc/${element.objectMantId}/generat`;
      return;
    }
    window.location.href = `./#/carregador/dc/${element.objectMantId}/previsualitzacio`;
  }


  newStmc() {
    localStorage.setItem("filtres",JSON.stringify(this.formData.value))
    localStorage.setItem('ordenacio', this.sortField);
    localStorage.setItem('currentPage', this.currentPage.toString());
    localStorage.setItem('itemsPerPage', this.itemsPerPage.toString());
    localStorage.setItem('totalPages', this.totalPages.toString());
    this.router.navigate(['/carregador/stmc']);
  }

  iconaTipus(tipus: string) {
    switch (tipus) {
      case 'DC_MODIFICACIO':
        return 'sd_card_alert';
      case 'DC_CREAT':
        return 'task';
      case 'DC_NO_CREAT':
        return 'error_outline'
    }
    return "";
  }

  textTipus(value: string) {
    if(!value) {
      return ""
    }
    return `CARREGADOR.${value}`;
  }

  onFocus(campo: string) {
    switch (campo) {
      case 'poblacioOrigen':
        this.isFocused.poblacioOrigen = true;
        break;
      case 'poblacioDesti':
        this.isFocused.poblacioDesti = true;
        break;
    }
  }

  onBlur(campo: string) {
    setTimeout(() => {
      switch (campo) {
        case 'poblacioOrigen':
          this.isFocused.poblacioOrigen = false;
          this.poblacions = [];
          break;
        case 'poblacioDesti':
          this.isFocused.poblacioDesti = false;
          this.poblacions = [];
          break;
      }
    }, 200);
  }

  setFormValue(campo: string, value: string, codi: string = "") {
    switch (campo) {
      case 'poblacioOrigen':
        this.formData.patchValue({ poblacioOrigen: value });
        this.codiPoblacioOrigen = codi;
        break;
      case 'poblacioDesti':
        this.formData.patchValue({ poblacioDesti: value });
        this.codiPoblacioDesti = codi;
    }
  }

  async changeContextPoblacio($event: any) {
    if($event.target.value.length >= 3) {
      this.poblacions = await lastValueFrom(this.contextService.getPoblacions($event.target.value))
    }
  }


  async localitazcions(element: Stmc) {
    const esSoloNumeros = (str: string) => /^\d+$/.test(str);
    let origen = "", desti = "";
    if(element.poblacioOrigen && esSoloNumeros(element.poblacioOrigen)) {
      const resp = await lastValueFrom(this.contextService.getPoblacions(element.poblacioOrigen))
      if(resp.length)
        origen = resp[0].descripcio;
    }
    if(element.poblacioDesti && esSoloNumeros(element.poblacioDesti)) {
      const resp = await lastValueFrom(this.contextService.getPoblacions(element.poblacioDesti))
      if(resp.length)
        desti = resp[0].descripcio;
    }
    return [origen, desti]
  }

  async deleteElement(element: any) {
    this.dataSource = this.dataSource.filter((e) => e !== element);
    await this.fetchData(this.formData.value)
  }

  async clearFilter() {
    this.formData.patchValue({
      codiStmc: '',
      nifRaoTransportista: '',
      dataRealitzacioInici: '',
      dataRealitzacioFi: '',
      matriculaVehicle: '',
      tipusMercaderia: '',
      poblacioOrigen: '',
      poblacioDesti: '',
      estatStmc: [],
      estatDc: []
    });
    this.estatsSelected = this.estats;
    this.estatsDcSelected = this.estatsDc;
    this.currentPage = 1;
    await this.fetchData()
  }

  showEstats() {
    this.mostrarEstats = !this.mostrarEstats;
  }
  showMesFiltres() {
    this.mesFiltres = !this.mesFiltres;
  }

  async onItemsPerPageChange(event: Event) {
    this.currentPage = 1;
    this.itemsPerPage = +(event.target as HTMLSelectElement).value;
    await this.fetchData(this.formData.value)
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.fetchData(this.formData.value)
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.fetchData(this.formData.value)
    }
  }

  goToPage(event: Event) {
    this.currentPage = +(event.target as HTMLSelectElement).value;
    this.fetchData(this.formData.value)
  }

  getValueByField(obj: any, field: string): any {
    return field.split('.').reduce((o, key) => (o ? o[key] : null), obj);
  }

  async sortFieldChange(event: any) {
    this.sortField = event.target.value;
    await this.updateSortField(this.sortField);
  }

  async updateSortField(field: string) {
    this.currentPage = 1;
    if( this.sortField == field){
      this.sortOrder = !this.sortOrder;
    } else {
      this.sortField = field;
    }
    await this.fetchData(this.formData.value);
  }

  async changeOrder() {
    this.currentPage = 1;
    this.sortOrder = !this.sortOrder;
    await this.fetchData(this.formData.value);
  }
  
}
