import { Component, inject, ViewChild } from '@angular/core';
import { lastValueFrom, Subscriber, Subscription } from 'rxjs';
import { StmcService } from '../../services/api/stmc.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StmcPopulated } from '../../types/StmcPopulated';
import { ContextService } from '../../services/api/context.service';
import { EmailDcComponent } from '../../shared/email/email-dc.component';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal.component';
import { ToastComponent } from '../../shared/toast/toast.component';

@Component({
  selector: 'app-dc',
  templateUrl: './dc.component.html',
  styleUrl: './dc.component.scss',
})
export class DcComponent {
  @ViewChild(ToastComponent) toast!: ToastComponent;
  dades: StmcPopulated = {
    dataRealitzacio: '',
    estatDc: "",
    rutaStmc: {
      poblacioOrigen: '',
      provinciaOrigen: '',
      codiPostalOrigen: '',
      adrecaOrigen: '',
      poblacioDesti: '',
      provinciaDesti: '',
      codiPostalDesti: '',
      adrecaDesti: '',
    },
    carregadorContractual: {
      nif: '',
      raoSocial: '',
      adreca: '',
      codiPostal: '',
      poblacio: '',
      observacions: '',
    },
    transportistaEfectiu: {
      nif: '',
      raoSocial: '',
      observacions: '',
    },
    mitjaTransport: {
      matriculaTractor: '',
      matriculaRemolc: '',
      matriculaTractorSubs1: '',
      matriculaRemolcSubs1: '',
      matriculaTractorSubs2: undefined,
      matriculaRemolcSubs2: undefined,
    },
    mercaderiaStmc: {
      tipusMercaderia: '',
      descripcioTipus: undefined,
      descripcioMercaderia: '',
      pes: null,
      requereixAutoritzacio: false,
      numeroAutorizacio: '',
    },
    estatStmc: '',
  };
  bodyFindBy: any;
  stmcService = inject(StmcService);
  contextService = inject(ContextService);
  activatedRoute = inject(ActivatedRoute);
  id: string = '';
  rol: string = '';
  mercaderies: any[] = [{descripcio: ""}];
  validat: boolean = false;
  copied: boolean = false;
  dcEnviatSubscription!: Subscription;
  provinces: { value: string; label: string }[] =  [
    { value: '01', label: 'Álava' },
    { value: '02', label: 'Albacete' },
    { value: '03', label: 'Alicante' },
    { value: '04', label: 'Almería' },
    { value: '05', label: 'Ávila' },
    { value: '06', label: 'Badajoz' },
    { value: '07', label: 'Islas Baleares' },
    { value: '08', label: 'Barcelona' },
    { value: '09', label: 'Burgos' },
    { value: '10', label: 'Cáceres' },
    { value: '11', label: 'Cádiz' },
    { value: '12', label: 'Castellón' },
    { value: '13', label: 'Ciudad Real' },
    { value: '14', label: 'Córdoba' },
    { value: '15', label: 'A Coruña' },
    { value: '16', label: 'Cuenca' },
    { value: '17', label: 'Girona' },
    { value: '18', label: 'Granada' },
    { value: '19', label: 'Guadalajara' },
    { value: '20', label: 'Guipúzcoa' },
    { value: '21', label: 'Huelva' },
    { value: '22', label: 'Huesca' },
    { value: '23', label: 'Jaén' },
    { value: '24', label: 'León' },
    { value: '25', label: 'Lleida' },
    { value: '26', label: 'La Rioja' },
    { value: '27', label: 'Lugo' },
    { value: '28', label: 'Madrid' },
    { value: '29', label: 'Málaga' },
    { value: '30', label: 'Murcia' },
    { value: '31', label: 'Navarra' },
    { value: '32', label: 'Ourense' },
    { value: '33', label: 'Asturias' },
    { value: '34', label: 'Palencia' },
    { value: '35', label: 'Las Palmas' },
    { value: '36', label: 'Pontevedra' },
    { value: '37', label: 'Salamanca' },
    { value: '38', label: 'Santa Cruz de Tenerife' },
    { value: '39', label: 'Cantabria' },
    { value: '40', label: 'Segovia' },
    { value: '41', label: 'Sevilla' },
    { value: '42', label: 'Soria' },
    { value: '43', label: 'Tarragona' },
    { value: '44', label: 'Teruel' },
    { value: '45', label: 'Toledo' },
    { value: '46', label: 'València' },
    { value: '47', label: 'Valladolid' },
    { value: '48', label: 'Vizcaya' },
    { value: '49', label: 'Zamora' },
    { value: '50', label: 'Zaragoza' },
    { value: '51', label: 'Ceuta' },
    { value: '52', label: 'Melilla' },
];

  constructor(private router: Router, private dialog: MatDialog) {}

  async ngOnInit() {
    this.rol = this.router.url.includes('transportista')
    ? 'transportista'
    : 'carregador';
    this.id = this.activatedRoute.snapshot.paramMap.get('id') || '';
    this.validat = this.activatedRoute.snapshot.paramMap.get('prev') === 'generat';
    let stmc = await lastValueFrom(this.stmcService.getStmcById(this.id));
    this.dades = stmc.dadesResultat;
    if(this.dades.mercaderiaStmc.tipusMercaderia) {
      this.mercaderies = await lastValueFrom(this.contextService.getMercaderies(this.dades.mercaderiaStmc.tipusMercaderia));
    } 
    if (this.dades.mercaderiaStmc.pes) {
      this.dades.mercaderiaStmc.pes /= 1000;
    }
    this.dcEnviatSubscription = this.stmcService.dcEnviatSubject$.subscribe(() => {
      this.toast.open('DC Enviat per correu electrònic', '');
    });
  }

  async tornar() {
    const lastStmc = await lastValueFrom(this.stmcService.stmcFindBy({
      "dadesPaginacio": {
        "numRegisters": 1,
        "numPagina": 1,
        "ordreCamp": 'estat',
        "ordreAsc": true
      },
      "filtresConsulta": {"codiStmc": this.dades.codiStmc, "estat": true, "pagina": 1, "rolSeleccionat": this.rol.toUpperCase()}
    }));
    if(lastStmc.dadesResultat) {
      this.router.navigate([`/${this.rol}/stmc/${lastStmc.dadesResultat[0].objectMantId}`]);
    } else {
      this.router.navigate([`/${this.rol}/stmc/${this.id}`]);
    }
  }

  async validar() {
    const validated = await lastValueFrom(this.stmcService.generarDc(this.dades.codiStmc));
    if(validated.validacioResultat) {
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: {
          title: 'ERRORS.'.concat(validated.validacioResultat[0].codiError),
          message: '',
          icon: 'warning',
          showCancel: false,
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(
        async (result: { accept: boolean }) => {
          this.dialog.closeAll();
        }
      );
      return;
    }
    if(validated.dadesResultat) {
      this.validat = true;
    }
  }

  copyCode(codi: string) {
    navigator.clipboard.writeText(codi);
    this.copied = true;
  }

  isTabValid(tabIndex: number): boolean {
    switch (tabIndex) {
      case 1: //Dades del carregador contractual
          return (
            this.dades.carregadorContractual.raoSocial !== '' &&
            this.dades.carregadorContractual.nif !== '' &&
            this.dades.carregadorContractual.adreca !== '' &&
            this.dades.carregadorContractual.codiPostal !== '' &&
            this.dades.carregadorContractual.poblacio !== ''
          );
      case 2: //Dades del transportista efectiu
        return (
          this.dades.transportistaEfectiu.nif !== '' &&
          this.dades.transportistaEfectiu.raoSocial !== ''
        );
      case 3: //Dades de la ruta (origen i destinació)
        return (
          this.dades.rutaStmc.poblacioDesti !== '' &&
          this.dades.rutaStmc.poblacioOrigen !== ''
        );
      case 4: //Dades dels vehicles
        return this.dades.mitjaTransport.matriculaTractor !== ''
      case 5: //Dades de la mercaderia
        return this.dades.mercaderiaStmc.descripcioTipus !== ''
      default:
        return false;
    }
  }

  email() {
    const dialogRef = this.dialog.open(EmailDcComponent, {
      data: { stmc: this.dades },
    });
    dialogRef.componentInstance.closeEvent.subscribe(() => {
      (result: { accept: boolean }) => {
        this.dialog.closeAll();
      }
    });
  }

  async pdf() {
    if (!this.dades.codiStmc) {
      return;
    }
    const resp = await lastValueFrom(
      this.stmcService.generarPdfDc(this.dades.codiStmc)
    );
    if (!resp.dadesResultat.contingutBase64) {
      return;
    }
    const binaryString = atob(resp.dadesResultat.contingutBase64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = this.dades.codiStmc.concat('.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
