<aside [ngClass]="show ? 'sidebarShow' : ''">
    <ul>
      <li [class.active]="isActive('/inici')" (click)="closeSidebar('/inici')">
          <mat-icon size="24px" class="icon" alt="inici">home</mat-icon>
          {{ "MENU.INICI" | translate }}
      </li>
      @if(carregador) {
        <li [class.active]="isActive('/carregador')" (click)="closeSidebar('/carregador')">
            <!-- <mat-icon size="24px" class="material-symbols-outlined" alt="carregador">forklift</mat-icon> -->
            <img width="24" heigth="24" src="../../../assets/images/forklift_3.png" alt="carregador"/>
              {{ "MENU.CARREGADOR" | translate }}
        </li>
      }
      @if(transportista) {
        <li [class.active]="isActive('/transportista')" (click)="closeSidebar('/transportista')">
            <mat-icon size="24px" class="icon" alt="transportista">local_shipping</mat-icon>
              {{ "MENU.TRANSPORTISTA" | translate }}
        </li>
      }
      @if(admin) {
        <li [class.active]="isActive('/administracio')" (click)="closeSidebar('/administracio')">
              <mat-icon size="24px" class="icon" alt="administracio">settings</mat-icon>
              {{ "MENU.ADMIN" | translate }}
        </li>
      }
      
    </ul>
  </aside>
  