import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HabitualService {
  private habitual = new Subject<{ type: string; element: any }>;
  habitual$ = this.habitual.asObservable();

  private favorit = new Subject<{ type: string; element: any }>;
  favorit$ = this.favorit.asObservable();

  private habitualAdmin = new Subject<{}>;
  habitualAdmin$ = this.habitualAdmin.asObservable();

  private habitualOpenModal = new Subject<{type: string, data: any, edit: boolean, openList: boolean}>;
  habitualOpenModal$ = this.habitualOpenModal.asObservable();

  emitHabitual(type: string, element: any) {
    this.habitual.next({ type, element });
  }
  emitFavotit(type: string, element: any) {
    this.favorit.next({ type, element });
  }
  emitHabitualAdmin() {
    this.habitualAdmin.next({});
  }
  emitHabitualOpenModal(type: string, data: any, edit: boolean, openList: boolean) {
    this.habitualOpenModal.next({type, data, edit, openList});
  }
}
