<main>
  <app-sidebar [admin]="true"></app-sidebar>
  <section>
    <div class="block">
      <div>
        <h1>{{ "CARREGADOR.TITLE" | translate }}</h1>
        <p class="sub">{{ "CARREGADOR.SUBTITLE" | translate }}</p>
      </div>
      <div class="desktop"></div>
    </div>
    <div class="block">
      <div class="dades" [formGroup]="formData">
        <h5>{{ "CARREGADOR.CERCA" | translate }}</h5>
        <div class="filtres">
          <div class="grid-inputs">
            <div class="input">
              <label>{{ "CARREGADOR.CODI" | translate }}:</label>
              <input
                matInput
                type="text"
                id="codiStmc"
                formControlName="codiStmc"
                placeholder="{{ 'CARREGADOR.CODI_PLACEHOLDER' | translate }}"
                class="ctti-input-text"
              />
            </div>
            <div class="input item2">
              <label>{{ "CARREGADOR.NIF" | translate }}:</label>
              <input
                matInput
                type="text"
                id="nif"
                formControlName="nifRaoCarregador"
                placeholder="{{ 'CARREGADOR.NIF_PLACEHOLDER' | translate }}"
                class="ctti-input-text"
              />
            </div>
            <div class="input">
              <label>{{ "CARREGADOR.DATES" | translate }}:</label>
              <div class="input-dates">
                <input
                  type="date"
                  class="ctti-input-text"
                  [class]="formData.value.dataRealitzacioInici ? 'enDada' : ''"
                  formControlName="dataRealitzacioInici"
                  placeholder="dd/mm/aaaa"
                />
                <input
                  type="date"
                  class="ctti-input-text"
                  [class]="formData.value.dataRealitzacioFi ? 'enDada' : ''"
                  formControlName="dataRealitzacioFi"
                  placeholder="dd/mm/aaaa"
                />
              </div>
            </div>
          </div>
          <div class="grid-inputs-mes">
            @if(mesFiltres) {
            <div class="input">
              <label>{{ "CARREGADOR.MATRICULA" | translate }}:</label>
              <input
                matInput
                type="text"
                id="matriculaVehicle"
                formControlName="matriculaVehicle"
                placeholder="{{
                  'CARREGADOR.MATRICULA_PLACEHOLDER' | translate
                }}"
                class="ctti-input-text"
              />
            </div>
            <div class="input">
              <label>{{ "CARREGADOR.TIPUS_MERCADERIA" | translate }}:</label>
              <select
                formControlName="tipusMercaderia"
                class="ctti-dropdown"
                [ngClass]="formData.value.tipusMercaderia ? 'enDada' : ''"
              >
                <option value="" selected>
                  {{ "CARREGADOR.MERCADERIA_PLACEHOLDER" | translate }}
                </option>
                @for(mercaderia of mercaderies; track mercaderia) {
                <option [value]="mercaderia.descripcio">
                  {{ mercaderia.descripcio }}
                </option>
                }
              </select>
            </div>
            <div class="input">
              <label>{{ "CARREGADOR.ORIGEN" | translate }}:</label>
              <input
                type="text"
                id="poblacioOrigen"
                class="ctti-input-text"
                formControlName="poblacioOrigen"
                (focus)="onFocus('poblacioOrigen')"
                (blur)="onBlur('poblacioOrigen')"
                (input)="changeContextPoblacio($event)"
                placeholder="{{ 'CARREGADOR.ORIGEN_PLACEHOLDER' | translate }}"
              />
              <ul
                *ngIf="poblacions.length > 0 && isFocused.poblacioOrigen"
                class="contextList"
              >
                <li
                  *ngFor="let poblacio of poblacions"
                  (click)="
                    setFormValue(
                      'poblacioOrigen',
                      poblacio.descripcio,
                      poblacio.codiElement
                    )
                  "
                >
                  {{ poblacio.descripcio }}
                </li>
              </ul>
            </div>
            <div class="input">
              <label>{{ "CARREGADOR.DESTI" | translate }}:</label>
              <input
                type="text"
                id="poblacioDesti"
                class="ctti-input-text"
                formControlName="poblacioDesti"
                (focus)="onFocus('poblacioDesti')"
                (blur)="onBlur('poblacioDesti')"
                (input)="changeContextPoblacio($event)"
                placeholder="{{ 'CARREGADOR.DESTI_PLACEHOLDER' | translate }}"
              />
              <ul
                *ngIf="poblacions.length > 0 && isFocused.poblacioDesti"
                class="contextList"
              >
                <li
                  *ngFor="let poblacio of poblacions"
                  (click)="
                    setFormValue(
                      'poblacioDesti',
                      poblacio.descripcio,
                      poblacio.codiElement
                    )
                  "
                >
                  {{ poblacio.descripcio }}
                </li>
              </ul>
            </div>
            <div class="input">
              <label>{{ "CARREGADOR.ESTATS" | translate }}:</label>
              <mat-select
                #dropdownMultiple
                multiple
                disableOptionCentering
                disableRipple
                placeholder="Selecciona estats"
                class="ctti-dropdown"
                formControlName="estatStmc"
                [ngClass]="formData.value.estatStmc.length ? 'enDada' : ''"
              >
                @for(item of estats; track item) { @if(item) {
                <mat-option [value]="item" class="opcioEstat">
                  <p>{{ textTipus(item) | translate }}</p>
                </mat-option>
                } }
              </mat-select>
            </div>
            <div class="input">
              <label>{{ "STMC.ESTATS_DC" | translate }}:</label>
              <mat-select
                #dropdownMultiple
                multiple
                disableOptionCentering
                disableRipple
                placeholder="Selecciona estats"
                class="ctti-dropdown"
                formControlName="estatDc"
                [ngClass]="formData.value.estatDc.length ? 'enDada' : ''"
              >
                @for(item of estatsDc; track item) { @if(item) {
                <mat-option [value]="item" class="opcioEstat">
                  <p>{{ textTipus(item) | translate }}</p>
                </mat-option>
                } }
              </mat-select>
            </div>
            }
          </div>
          <div class="botons-filtres">
            <button
              class="ctti-button ctti-button-inv"
              (click)="showMesFiltres()"
            >
              @if(!mesFiltres) {
              <mat-icon>add</mat-icon>
              {{ "CARREGADOR.MES_FILTRES" | translate }}
              } @else {
              <mat-icon>remove</mat-icon>
              {{ "CARREGADOR.MENYS_FILTRES" | translate }}
              }
            </button>
            <div class="accions-filtres">
              <button
                class="ctti-button ctti-button-trans"
                (click)="clearFilter()"
              >
                {{ "CARREGADOR.CLEAR" | translate }}
              </button>
              <button
                class="ctti-button ctti-button-filled"
                (click)="applyFilter()"
              >
                <mat-icon>search</mat-icon>{{ "CARREGADOR.SEARCH" | translate }}
              </button>
            </div>
          </div>
        </div>
        <br />
        <div class="crear tablet">
          <h5>{{ "CARREGADOR.STMC_NEW" | translate }}</h5>
          <div class="saveBox">
            <button
              class="desar ctti-button ctti-button-filled"
              (click)="newStmc()"
            >
              <mat-icon>add</mat-icon>
              {{ "CARREGADOR.STMC_NEW_BTN" | translate }}
            </button>
          </div>
        </div>
        <h5>{{ "CARREGADOR.RESULTATS" | translate }}</h5>
        <div class="stmcs">
          <div class="order">
            <select
              [value]="sortField"
              class="ctti-dropdown enDada"
              (change)="sortFieldChange($event)"
            >
              <option
                value="poblacioOrigen"
                (click)="updateSortField('poblacioOrigen')"
              >
                {{ "CARREGADOR.ORIGEN_ORD" | translate }}
              </option>
              <option
                value="poblacioDesti"
                (click)="updateSortField('poblacioDesti')"
              >
                {{ "CARREGADOR.DESTINACIO_ORD" | translate }}
              </option>
              <option
                value="tipusMercaderia"
                (click)="updateSortField('tipusMercaderia')"
              >
                {{ "CARREGADOR.MERCADERIA_ORD" | translate }}
              </option>
              <option value="estatStmc" (click)="updateSortField('estatStmc')">
                {{ "CARREGADOR.ESTAT_ORD" | translate }}
              </option>
              <option
                value="dataRealitzacio"
                (click)="updateSortField('dataRealitzacio')"
              >
                {{ "CARREGADOR.DATA_ORD" | translate }}
              </option>
              <option
                value="raoSocialCarregador"
                (click)="updateSortField('raoSocialCarregador')"
              >
                {{ "CARREGADOR.RAOSOCIAL_ORD" | translate }}
              </option>
            </select>
            <mat-icon (click)="changeOrder()">{{
              sortOrder ? "arrow_upward" : "arrow_downward"
            }}</mat-icon>
          </div>
          <table>
            <tr *ngFor="let element of dataSource" class="stmc-row">
              <td class="codi">
                <h5>{{ element.codiStmc }}</h5>

                <div [class]="element.estatStmc.toLowerCase()">
                  {{ textTipus(element.estatStmc) | translate }}
                  @if(element.estatDc) {
                  <div [class]="element.estatDc.toLowerCase()">
                    <mat-icon>{{ iconaTipus(element.estatDc) }}</mat-icon>
                  </div>
                  }
                </div>
              </td>
              <td>
                <div class="dades-smtc">
                  <div class="dada-smtc">
                    <div><mat-icon>calendar_today</mat-icon></div>
                    <p>{{ element.dataRealitzacio }}</p>
                  </div>
                  <div class="dada-smtc">
                    <div><mat-icon>location_on</mat-icon></div>
                    <p>
                      {{
                        element.poblacioOrigen + " - " + element.poblacioDesti
                      }}
                      <!-- {{ localitazcions(element) }} -->
                    </p>
                  </div>
                  <div class="dada-smtc">
                    <div><mat-icon>forklift</mat-icon></div>
                    <p>{{ element.raoSocialCarregador }}</p>
                  </div>
                  <div class="dada-smtc">
                    <div><mat-icon>inventory</mat-icon></div>
                    <p>
                      {{ element.descripcioTipus || "" | translate }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <div class="actions dt">
                  <p
                    class="red"
                    (click)="viewElement(element)"
                    *ngIf="
                      [
                        'ESBORRANY',
                        'REBUTJAT',
                        'PLANIFICAT',
                        'DC_CREAT',
                        'CANCELAT',
                        'REALIZAT',
                        'NO_REALIZAT'
                      ].includes(element.estatStmc)
                    "
                  >
                    <mat-icon>visibility</mat-icon>
                  </p>
                  <p
                    class="red"
                    (click)="editElement(element.objectMantId)"
                    *ngIf="
                      [
                        'ESBORRANY',
                        'PLANIFICAT',
                        'DC_CREAT',
                        'REBUTJAT'
                      ].includes(element.estatStmc)
                    "
                  >
                    <mat-icon>edit</mat-icon>
                  </p>
                  <p class="red" (click)="duplicar(element.codiStmc)">
                    <mat-icon>content_copy</mat-icon>
                  </p>
                  <p
                    class="red"
                    [matMenuTriggerFor]="item"
                    *ngIf="
                      [
                        'ESBORRANY',
                        'PLANIFICAT',
                        'REBUTJAT',
                        'REALIZAT'
                      ].includes(element.estatStmc)
                    "
                  >
                    <mat-icon>more_horiz</mat-icon>
                  </p>
                  <mat-menu #item="matMenu" yPosition="below">
                    <ul class="more">
                      <li (click)="generarDC(element)">
                        <mat-icon>task_alt</mat-icon
                        >{{
                          element.estatDc == "DC_CREAT"
                            ? "CARREGADOR.VISUALITZAR_DC"
                            : ("CARREGADOR.GENERAR_DC" | translate)
                        }}
                      </li>
                      <li (click)="reasignar(element)">
                        <mat-icon>sync_alt</mat-icon
                        >{{ "CARREGADOR.REASSIGNAR" | translate }}
                      </li>
                      <li
                        (click)="rebutjar(element)"
                        *ngIf="
                          ['ESBORRANY', 'PLANIFICAT'].includes(
                            element.estatStmc
                          )
                        "
                      >
                        <mat-icon>block</mat-icon
                        >{{ "CARREGADOR.REBUTJAR" | translate }}
                      </li>
                      <li
                        (click)="cancelar(element)"
                        *ngIf="
                          ['ESBORRANY', 'PLANIFICAT', 'REBUTJAT'].includes(
                            element.estatStmc
                          )
                        "
                      >
                        <mat-icon>cancel</mat-icon
                        >{{ "CARREGADOR.CANCELAR" | translate }}
                      </li>
                      <li
                        (click)="realitzar(element)"
                        *ngIf="
                          ['ESBORRANY', 'PLANIFICAT', 'REBUTJAT'].includes(
                            element.estatStmc
                          )
                        "
                      >
                        <mat-icon>done_all</mat-icon
                        >{{ "CARREGADOR.REALIZAR" | translate }}
                      </li>
                    </ul>
                  </mat-menu>
                </div>
                <div class="actions mobile">
                  <div class="mainAction" (click)="generarDC(element)">
                    <mat-icon>task_alt</mat-icon>
                    <p class="red">
                      {{
                        (element.estatDc == "DC_CREAT"
                          ? "CARREGADOR.VISUALITZAR_DC"
                          : "CARREGADOR.GENERAR_DC"
                        ) | translate
                      }}
                    </p>
                  </div>
                  <div class="others" [matMenuTriggerFor]="itemMobile">
                    <mat-icon>more_horiz</mat-icon>
                  </div>
                  <mat-menu #itemMobile="matMenu" yPosition="below">
                    <ul class="more">
                      <li
                        (click)="viewElement(element)"
                        *ngIf="
                          [
                            'ESBORRANY',
                            'REBUTJAT',
                            'PLANIFICAT',
                            'DC_CREAT',
                            'CANCELAT',
                            'REALIZAT',
                            'NO_REALIZAT'
                          ].includes(element.estatStmc)
                        "
                      >
                        <mat-icon>visibility</mat-icon>
                        <p>{{ "CARREGADOR.VIEW" | translate }}</p>
                      </li>
                      <li
                        (click)="editElement(element.objectMantId)"
                        *ngIf="
                          [
                            'ESBORRANY',
                            'PLANIFICAT',
                            'DC_CREAT',
                            'REBUTJAT'
                          ].includes(element.estatStmc)
                        "
                      >
                        <mat-icon>edit</mat-icon>
                        <p>{{ "CARREGADOR.EDIT" | translate }}</p>
                      </li>
                      <li (click)="duplicar(element.codiStmc)">
                        <mat-icon>content_copy</mat-icon>
                        <p>{{ "CARREGADOR.DUPLICATE" | translate }}</p>
                      </li>
                      <li
                        (click)="reasignar(element)"
                        [matMenuTriggerFor]="item"
                      >
                        <mat-icon>sync_alt</mat-icon>
                        <p>{{ "CARREGADOR.REASSIGNAR" | translate }}</p>
                      </li>
                      <li
                        (click)="rebutjar(element)"
                        [matMenuTriggerFor]="item"
                        *ngIf="
                          ['ESBORRANY', 'PLANIFICAT'].includes(
                            element.estatStmc
                          )
                        "
                      >
                        <mat-icon>block</mat-icon>
                        <p>{{ "CARREGADOR.REBUTJAR" | translate }}</p>
                      </li>
                      <li
                        (click)="cancelar(element)"
                        *ngIf="
                          ['ESBORRANY', 'PLANIFICAT', 'REBUTJAT'].includes(
                            element.estatStmc
                          )
                        "
                      >
                        <mat-icon>cancel</mat-icon>
                        <p>{{ "CARREGADOR.CANCELAR" | translate }}</p>
                      </li>
                      <li
                        (click)="realitzar(element)"
                        *ngIf="
                          ['ESBORRANY', 'PLANIFICAT', 'REBUTJAT'].includes(
                            element.estatStmc
                          )
                        "
                      >
                        <mat-icon>done_all</mat-icon>
                        <p>{{ "CARREGADOR.REALIZAR" | translate }}</p>
                      </li>
                    </ul>
                  </mat-menu>
                </div>
              </td>
            </tr>
          </table>

          <div class="pagination-controls">
            <div class="items-page">
              <select id="itemsPerPage" (change)="onItemsPerPageChange($event)">
                <option *ngFor="let size of pageSizeOptions" [value]="size">
                  {{ size }} / {{ "CARREGADOR.PAG" | translate }}
                </option>
              </select>
              <p class="paginacio">
                {{ "CARREGADOR.DE" | translate }} {{ totalRegistres }}
                {{ "CARREGADOR.ELEMENTS" | translate }}
              </p>
            </div>
            <div class="page-number">
              <select
                [ngModel]="currentPage"
                (change)="goToPage($event)"
                formControlName="pagina"
              >
                <option *ngFor="let index of pageNumberArr" [value]="index">
                  {{ index }}
                </option>
              </select>
              <p class="paginacio">
                {{ "CARREGADOR.DE" | translate }} {{ totalPages }}
                {{ "CARREGADOR.PAGINES" | translate }}
              </p>
              @if(currentPage !== 1) {
              <button (click)="previousPage()">
                <mat-icon>chevron_left</mat-icon>
              </button>
              } @if(currentPage !== totalPages) {
              <button (click)="nextPage()">
                <mat-icon>chevron_right</mat-icon>
              </button>
              }
            </div>
          </div>
        </div>

        <div class="estats">
          <div class="estats-drop" (click)="showEstats()">
            <h5>{{ "CARREGADOR.LLEGENDA" | translate }}</h5>
            <div>
              @if(mostrarEstats) {
              <mat-icon>expand_less</mat-icon>
              } @else {
              <mat-icon>expand_more</mat-icon>
              }
            </div>
          </div>
          @if(mostrarEstats){
          <div class="llista-estats">
            <div>
              <div class="esborrany">
                {{ "CARREGADOR.ESBORRANY" | translate }}
              </div>
              <p>{{ "CARREGADOR.ESBORRANY_DESC" | translate }}</p>
            </div>
            <div>
              <div class="planificat">
                {{ "CARREGADOR.PLANIFICAT" | translate }}
              </div>
              <p>{{ "CARREGADOR.PLANIFICAT_DESC" | translate }}</p>
            </div>
            <div>
              <div class="no_realizat">
                {{ "CARREGADOR.NO_REALIZAT" | translate }}
              </div>
              <p>{{ "CARREGADOR.NOREALITZAT_DESC" | translate }}</p>
            </div>
            <div>
              <div class="realizat">
                {{ "CARREGADOR.REALIZAT" | translate }}
              </div>
              <p>{{ "CARREGADOR.REALITZAT_DESC" | translate }}</p>
            </div>
            <div>
              <div class="rebutjat">
                {{ "CARREGADOR.REBUTJAT" | translate }}
              </div>
              <p>{{ "CARREGADOR.REBUTJAT_DESC" | translate }}</p>
            </div>
            <div>
              <div class="cancelat">
                {{ "CARREGADOR.CANCELAT" | translate }}
              </div>
              <p>{{ "CARREGADOR.CANCELAT_DESC" | translate }}</p>
            </div>

            <div>
              <div class="dc_creat">
                <mat-icon>task</mat-icon>
              </div>
              <p>{{ "CARREGADOR.DC_CREAT_DESC" | translate }}</p>
            </div>
            <div>
              <div class="dc_modificacio">
                <mat-icon>sd_card_alert </mat-icon>
              </div>
              <p>{{ "CARREGADOR.DC_MODIFICACIO_DESC" | translate }}</p>
            </div>
            <div>
              <div class="dc_no_creat">
                <mat-icon>error_outline</mat-icon>
              </div>
              <p>{{ "CARREGADOR.DC_NO_CREAT_DESC" | translate }}</p>
            </div>
          </div>
          }
        </div>
      </div>
      <div class="crear desktop">
        <h5>{{ "CARREGADOR.STMC_NEW" | translate }}</h5>
        <div class="saveBox">
          <button
            class="desar ctti-button ctti-button-filled"
            (click)="newStmc()"
          >
            <mat-icon>add</mat-icon>
            {{ "CARREGADOR.STMC_NEW_BTN" | translate }}
          </button>
        </div>
      </div>
    </div>
  </section>
</main>
