<aside [ngClass]="show ? 'sidebarShow' : ''">
    <ul>
      <li [class.active]="isActive('/inici')" (click)="closeSidebar('/inici')">
          <mat-icon size="24px" class="icon">home</mat-icon>
          {{ "MENU.INICI" | translate }}
      </li>
      @if(carregador) {
        <li [class.active]="isActive('/carregador')" (click)="closeSidebar('/carregador')">
              <img src="../../../assets/images/forklift.png" class="icon" alt="carregador"/>
              {{ "MENU.CARREGADOR" | translate }}
        </li>
      }
      @if(transportista) {
        <li [class.active]="isActive('/transportista')" (click)="closeSidebar('/transportista')">
              <img src="../../../assets/images/local_shipping.png" class="icon" alt="transportista"/>
              {{ "MENU.TRANSPORTISTA" | translate }}
        </li>
      }
      @if(admin) {
        <li [class.active]="isActive('/administracio')" (click)="closeSidebar('/administracio')">
              <mat-icon size="24px" class="icon" alt="administracio">settings</mat-icon>
              {{ "MENU.ADMIN" | translate }}
        </li>
      }
      
    </ul>
  </aside>
  