import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Ruta } from '../../types/Ruta';
import { environment } from '../../../environments/environment';

interface ResponseMultiple {
  dadesResultat: Ruta[]
  validacioResultat: any,
  dadesPaginacio: any
}

interface ResponseSingle {
  dadesResultat: Ruta
  validacioResultat: any
}

@Injectable({
  providedIn: 'root'
})
export class RutaService {
  private apiUrl: string = `${environment.apiUrl}ruta`;
  
  constructor(private http: HttpClient) {}

  createRuta(Ruta: Ruta): Observable<ResponseSingle> {
    return this.http.post<ResponseSingle>(this.apiUrl, Ruta);
  }

  getRutaById(id: string): Observable<ResponseSingle> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<ResponseSingle>(url);
  }

  rutaFindBy(filters: any): Observable<ResponseMultiple> {
    const url = `${this.apiUrl}/findBy`;
    return this.http.post<ResponseMultiple>(url, filters);
  }

  updateRuta(Ruta: Ruta): Observable<ResponseSingle> {
    const url = `${this.apiUrl}/${Ruta.objectMantId}`;
    return this.http.put<ResponseSingle>(url, Ruta);
  }

  deleteRuta(id: string): Observable<void> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete<void>(url);
  }
 
}
