<app-toast></app-toast>
<main [formGroup]="formData">
  <app-sidebar [admin]="true"></app-sidebar>
  <section>
    <div class="seccio">
      <h3 class="title3">{{ "PERFIL.TITLE" | translate }}</h3>
      <p class="noMobile seccio">{{ "PERFIL.SUBTITLE" | translate }}</p>
    </div>
    <div id="formPerfil">
      <div class="headerForm"></div>
      <div class="imatge">
        @if(imageOriginal.usuariFitxer64) {
          <img [src]="imageOriginal.usuariFitxer64" alt="imatge_perfil"/>
        }
        <input
          type="file"
          #fileInput
          accept="image/*"
          style="display: none"
          (change)="onFileSelected($event)"
        />
      </div>
      <div class="dades">
        <div class="botons">
          <button class="ctti-button ctti-button-filled" (click)="toggleEditMode()">
            {{ "PERFIL.EDITAR" | translate }}
            <mat-icon>edit</mat-icon>
          </button>
        </div>
        <h5 class="title5">
          <span>{{ "REGISTRE.COMPANY_INFO_TITLE" | translate }}</span>
          <mat-icon *ngIf="!isWeb" (click)="setSeccio(0)">{{
            seccions[0] ? "keyboard_arrow_up" : "keyboard_arrow_down"
          }}</mat-icon>
        </h5>
        <div *ngIf="isWeb || seccions[0]" class="seccio">
          <div class="col">
            <label for="raoSocial"
              >{{ "REGISTRE.COMPANY_NAME_LABEL" | translate }}:</label
            >
            <p>{{ dadesPerfil.raoSocial }}</p>
          </div>
          <div class="row">
            <div class="col">
              <label for="nifEmpresa"
                >{{ "REGISTRE.COMPANY_NIF_LABEL" | translate }}:</label
              >
              <p>{{ dadesPerfil.nifEmpresa }}</p>
            </div>

            <div class="col">
              <label for="rol">{{ "REGISTRE.ROLE_LABEL" | translate }}:</label>
              <p class="rolText">
                {{ getRolValue(dadesPerfil.rol) }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label for="emailEmpresa"
                >{{ "REGISTRE.PROFESSIONAL_EMAIL_LABEL" | translate }}:</label
              >
              <p>{{ dadesPerfil.emailEmpresa }}</p>
            </div>
            <div class="col">
              <label for="telefonEmpresa"
                >{{ "REGISTRE.PROFESSIONAL_PHONE_LABEL" | translate }}:</label
              >
              <p>{{ dadesPerfil.telefonEmpresa }}</p>
            </div>
          </div>
        </div>

        <h5 class="title5">
          <span>{{ "REGISTRE.COMPANY_ADDRESS_TITLE" | translate }}</span>
          <mat-icon *ngIf="!isWeb" (click)="setSeccio(1)">{{
            seccions[1] ? "keyboard_arrow_up" : "keyboard_arrow_down"
          }}</mat-icon>
        </h5>
        <div *ngIf="isWeb || seccions[1]" class="seccio">
          <div class="row">
            <div class="col">
              <label for="adrecaEmpresa">
                {{ "REGISTRE.COMPANY_ADDRESS_LABEL" | translate }}:</label
              >
              <p>{{ dadesPerfil.adrecaEmpresa }}</p>
            </div>
            <div class="col">
              <label for="codiPostalEmpresa"
                >{{ "REGISTRE.COMPANY_ZIP_LABEL" | translate }}:</label
              >
              <p>{{ dadesPerfil.codiPostalEmpresa }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label for="poblacio"
                >{{ "REGISTRE.COMPANY_CITY_LABEL" | translate }}:</label
              >
              <p>{{ dadesPerfil.poblacio }}</p>
            </div>
          </div>
        </div>

        <h5 class="title5">
          <span>{{ "REGISTRE.PROFESSIONAL_INFO_TITLE" | translate }}</span>
          <mat-icon *ngIf="!isWeb" (click)="setSeccio(2)">{{
            seccions[2] ? "keyboard_arrow_up" : "keyboard_arrow_down"
          }}</mat-icon>
        </h5>
        <div *ngIf="isWeb || seccions[2]" class="seccio">
          <div class="row">
            <div class="col">
              <label for="dniUsuari"
                >{{ "REGISTRE.PROFESSIONAL_NIF_LABEL" | translate }}:</label
              >
              <p>{{ dadesPerfil.dniUsuari }}</p>
            </div>
            <div class="col">
              <label for="nomProfessional"
                >{{ "REGISTRE.PROFESSIONAL_NAME_LABEL" | translate }}:</label
              >
              <p>{{ dadesPerfil.nomProfessional }} {{" "}} {{ dadesPerfil.primerCognom }} {{" "}} {{ dadesPerfil?.secCognom || "" }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label for="emailProfessional"
                >{{ "REGISTRE.PROFESSIONAL_EMAIL_LABEL" | translate }}:</label
              >
              <p>{{ dadesPerfil.emailProfessional }}</p>
            </div>
            <div class="col">
              <label for="telefonProfessional"
                >{{ "REGISTRE.PROFESSIONAL_PHONE_LABEL" | translate }}:</label
              >
              <p>
                {{ dadesPerfil.telefonProfessional }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label 
                >{{ "REGISTRE.PERFIL_LABEL" | translate }}:</label
              >
              <p>{{ dadesPerfil.codiPerfil === "PROF_ADMIN" ? "Administrador" : "Gestor" }}</p>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-sidepanel #panel>
    <div class="top-row">
      <div class="title">
        <mat-icon>edit</mat-icon>
        <h4 class="title4">{{ "REGISTRE.EDIT" | translate }}</h4>
      </div>
      <div class="botones">
        <button class="ctti-button ctti-button-trans" (click)="panel.close()">
          {{ "MODAL.CANCEL" | translate }}
        </button>
        <button class="ctti-button ctti-button-filled" (click)="onSubmit()">
          <mat-icon>save</mat-icon>
          {{ "MODAL.SAVE" | translate }}
        </button>
      </div>
    </div>
    <hr />
    <p>{{ "REGISTRE.EDIT_SUB" | translate }}</p>
    @if(this.usuari.codiPerfil == "PROF_ADMIN") {
    <h5 class="title5">
      <span>{{ "REGISTRE.COMPANY_INFO_TITLE" | translate }}</span>
      <mat-icon *ngIf="!isWeb" (click)="setSeccio(0)">{{
        seccions[0] ? "keyboard_arrow_up" : "keyboard_arrow_down"
      }}</mat-icon>
    </h5>
    <div *ngIf="isWeb || seccions[0]" class="seccio">
      <div class="col">
        <label for="raoSocial"
          ><span class="red">*</span
          >{{ "REGISTRE.COMPANY_NAME_LABEL" | translate }}:</label
        >
        <input
          matInput
          type="text"
          id="raoSocial"
          formControlName="raoSocial"
          placeholder="{{ 'REGISTRE.COMPANY_NAME_PLACEHOLDER' | translate }}"
          class="ctti-input-text"
        />
      </div>
      <div class="row">
        <div class="col">
          <label for="nifEmpresa"
            ><span class="red">*</span
            >{{ "REGISTRE.COMPANY_NIF_LABEL" | translate }}:</label
          >
          <input
            type="text"
            id="nifEmpresa"
            formControlName="nifEmpresa"
            placeholder="{{ 'REGISTRE.COMPANY_NIF_PLACEHOLDER' | translate }}"
            class="ctti-input-text"
            [disabled]="true"
          />
        </div>
        <div class="col">
          <label for="rol"><span class="red">*</span
            >{{ "REGISTRE.ROLE_LABEL" | translate }}:</label>
          <div class="rols">
            <mat-checkbox
              id="carregador"
              [checked]="isChecked('CARR')"
              (change)="onRoleChange('CARR')"
              ><span class="marge">{{
                "REGISTRE.ROLE_LOADER" | translate
              }}</span></mat-checkbox
            >
            <mat-checkbox
              id="transportista"
              [checked]="isChecked('TRAN')"
              (change)="onRoleChange('TRAN')"
              ><span class="marge">{{
                "REGISTRE.ROLE_TRANSPORTER" | translate
              }}</span></mat-checkbox
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="emailEmpresa"
            ><span class="red">*</span
            >{{ "REGISTRE.PROFESSIONAL_EMAIL_LABEL" | translate }}:</label
          >
          <input
            matInput
            type="text"
            id="emailEmpresa"
            formControlName="emailEmpresa"
            placeholder="{{
              'REGISTRE.PROFESSIONAL_EMAIL_PLACEHOLDER' | translate
            }}"
            class="ctti-input-text"
          />
        </div>
        <div class="col">
          <label for="telefonEmpresa"
            ><span class="red">*</span
            >{{ "REGISTRE.PROFESSIONAL_PHONE_LABEL" | translate }}:</label
          >
          <input
            matInput
            type="text"
            id="telefonEmpresa"
            formControlName="telefonEmpresa"
            placeholder="{{
              'REGISTRE.PROFESSIONAL_PHONE_PLACEHOLDER' | translate
            }}"
            class="ctti-input-text"
            maxlength="12"
          />
        </div>
      </div>
    </div>
    <hr />
    <h5 class="title5">
      <span>{{ "REGISTRE.COMPANY_ADDRESS_TITLE" | translate }}</span>
      <mat-icon *ngIf="!isWeb" (click)="setSeccio(1)">{{
        seccions[1] ? "keyboard_arrow_up" : "keyboard_arrow_down"
      }}</mat-icon>
    </h5>
    <div *ngIf="isWeb || seccions[1]" class="seccio">
      <div class="row">
        <div class="col">
          <label for="adrecaEmpresa">
            <span class="red">*</span>
            {{ "REGISTRE.COMPANY_ADDRESS_LABEL" | translate }}:</label
          >
          <input
            matInput
            type="text"
            id="adrecaEmpresa"
            formControlName="adrecaEmpresa"
            placeholder="{{
              'REGISTRE.COMPANY_ADDRESS_PLACEHOLDER' | translate
            }}"
            class="ctti-input-text"
            required
          />
        </div>
        <div class="col">
          <label for="codiPostalEmpresa"
            ><span class="red">*</span
            >{{ "REGISTRE.COMPANY_ZIP_LABEL" | translate }}:</label
          >
          <input
            matInput
            type="text"
            id="codiPostalEmpresa"
            formControlName="codiPostalEmpresa"
            placeholder="{{ 'REGISTRE.COMPANY_ZIP_PLACEHOLDER' | translate }}"
            class="ctti-input-text"
            maxlength="5"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="poblacio"
            ><span class="red">*</span
            >{{ "REGISTRE.COMPANY_CITY_LABEL" | translate }}:</label
          >
          <input
            type="text"
            id="poblacio"
            class="ctti-input-text"
            formControlName="poblacio"
            (focus)="onFocus()"
            (blur)="onBlur()"
            (input)="changeContextPoblacio($event)"
            placeholder="{{ 'STMC.CITY_PLACEHOLDER' | translate }}"
          />
          <ul *ngIf="poblacions.length > 0 && isFocused" class="contextList">
            <li
              *ngFor="let poblacio of poblacions"
              (click)="setFormValue(poblacio.descripcio, poblacio.codiElement)"
            >
              {{ poblacio.descripcio }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr />
    }
    <h5 class="title5">
      <span>{{ "REGISTRE.PROFESSIONAL_INFO_TITLE" | translate }}</span>
      <mat-icon *ngIf="!isWeb" (click)="setSeccio(2)">{{
        seccions[2] ? "keyboard_arrow_up" : "keyboard_arrow_down"
      }}</mat-icon>
    </h5>
    <div *ngIf="isWeb || seccions[2]" class="seccio">
      <div class="row">
        <div class="col">
          <label for="dniUsuari"
            ><span class="red">*</span
            >{{ "REGISTRE.PROFESSIONAL_NIF_LABEL" | translate }}:</label
          >
          <input
            type="text"
            id="dniUsuari"
            formControlName="dniUsuari"
            placeholder="{{
              'REGISTRE.PROFESSIONAL_NIF_PLACEHOLDER' | translate
            }}"
            class="ctti-input-text"
            [disabled]="true"
          />
        </div>
        <div class="col">
          <label for="nomProfessional"
            ><span class="red">*</span
            >{{ "REGISTRE.PROFESSIONAL_NAME_LABEL" | translate }}:</label
          >
          <input
            matInput
            type="text"
            id="nomProfessional"
            formControlName="nomProfessional"
            placeholder="{{
              'REGISTRE.PROFESSIONAL_NAME_PLACEHOLDER' | translate
            }}"
            class="ctti-input-text"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="primerCognom"
            ><span class="red">*</span
            >{{
              "REGISTRE.PROFESSIONAL_FIRST_SURNAME_LABEL" | translate
            }}:</label
          >
          <input
            matInput
            type="text"
            id="primerCognom"
            formControlName="primerCognom"
            placeholder="{{
              'REGISTRE.PROFESSIONAL_FIRST_SURNAME_PLACEHOLDER' | translate
            }}"
            class="ctti-input-text"
          />
        </div>
        <div class="col">
          <label for="secCognom"
            >{{
              "REGISTRE.PROFESSIONAL_SECOND_SURNAME_LABEL" | translate
            }}:</label
          >
          <input
            matInput
            type="text"
            id="secCognom"
            formControlName="secCognom"
            placeholder="{{
              'REGISTRE.PROFESSIONAL_SECOND_SURNAME_PLACEHOLDER' | translate
            }}"
            class="ctti-input-text"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="emailProfessional"
            ><span class="red">*</span
            >{{ "REGISTRE.PROFESSIONAL_EMAIL_LABEL" | translate }}:</label
          >
          <input
            matInput
            type="text"
            id="emailProfessional"
            formControlName="emailProfessional"
            placeholder="{{
              'REGISTRE.PROFESSIONAL_EMAIL_PLACEHOLDER' | translate
            }}"
            class="ctti-input-text"
            required
          />
        </div>
        <div class="col">
          <label for="telefonProfessional"
            >{{ "REGISTRE.PROFESSIONAL_PHONE_LABEL" | translate }}:</label
          >
          <input
            matInput
            type="text"
            id="telefonProfessional"
            formControlName="telefonProfessional"
            placeholder="{{
              'REGISTRE.PROFESSIONAL_PHONE_PLACEHOLDER' | translate
            }}"
            class="ctti-input-text"
            maxlength="12"
          />
        </div>
      </div>
    </div>
    <hr />
    <h5 class="title5">
      <span>{{ "REGISTRE.IMATGE_PERFIL" | translate }}</span>
      <mat-icon *ngIf="!isWeb" (click)="setSeccio(3)">{{
        seccions[2] ? "keyboard_arrow_up" : "keyboard_arrow_down"
      }}</mat-icon>
    </h5>
    <p>{{ "REGISTRE.IMATGE_PERFIL_SUB" | translate }}</p>
    <div class="pujarImatge">
      <div>
        <button class="fileinput ctti-button ctti-button-filled" (click)="onImageClick()">
          <mat-icon>description</mat-icon>
          {{ "STMC.ATTACHED_FILES" | translate }}
        </button>
        <input
          #fileInput
          accept="image/*"
          type="file"
          (change)="onFileSelected($event)"
        />
        @if( image.nomFitxer) {
        <div class="fileName">
          <span>{{ image.nomFitxer }}</span>
          <mat-icon (click)="removeFile()">delete</mat-icon>
        </div>
        }
      </div>
      <div>@if(image.usuariFitxer64) { <img [src]="image.usuariFitxer64" class="imatgeForm" alt="nova imatge de perfil"/> }</div>
    </div>
  </app-sidepanel>
</main>
