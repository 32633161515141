import {
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { lastValueFrom } from 'rxjs';
import { ToastComponent } from '../../shared/toast/toast.component';
import { UsuariService } from '../../services/api/usuari.service';
import { Empresa } from '../../types/Empresa';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ContextService } from '../../services/api/context.service';
import { SidepanelComponent } from '../../shared/sidepanel/sidepanel.component';
import { SidebarToggleService } from '../../services/sidebarToggle.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {
  @ViewChild(SidepanelComponent) sidepanel!: SidepanelComponent;
  @ViewChild(ToastComponent) toast!: ToastComponent;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  translate = inject(TranslateService);
  contextService = inject(ContextService);
  sidebarToggleService = inject(SidebarToggleService);
  breakpointObserver = inject(BreakpointObserver);
  usuariService = inject(UsuariService);

  tooltipText: string = '';
  usuari!: any;
  empresa!: Empresa;
  isWeb: boolean = false;
  esborrada: boolean = false;
  infoDesplegada: boolean = false;
  seccions: boolean[] = [false, false, false, false];
  poblacions: any[] = [];
  isFocused: boolean = false;
  codiPoblacio: string = '';
  image: { usuariFitxer64: string; nomFitxer: string } = {
    usuariFitxer64: '',
    nomFitxer: '',
  };
  imageOriginal: { usuariFitxer64: string; nomFitxer: string } = {
    usuariFitxer64: '',
    nomFitxer: '',
  };
  file: File | null = null;
  formData: FormGroup = this.fb.group({
    raoSocial: ['', Validators.required],
    nifEmpresa: [{ value: '', disabled: true }, Validators.required],
    rol: ['', Validators.required],
    emailEmpresa: ['', Validators.pattern(/^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}$/)],
    telefonEmpresa: ['', [Validators.required]],
    adrecaEmpresa: ['', Validators.required],
    codiPostalEmpresa: ['', Validators.pattern(/^\d{5}$/)],
    poblacio: ['', Validators.required],
    dniUsuari: [{ value: '', disabled: true }, Validators.required],
    nomProfessional: ['', Validators.required],
    primerCognom: ['', Validators.required],
    secCognom: [''],
    emailProfessional: ['', Validators.pattern(/^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}$/)],
    telefonProfessional: [''],
  });
  dadesPerfil: any = {
    raoSocial: '',
    nifEmpresa: '',
    rol: '',
    emailEmpresa: '',
    telefonEmpresa: '',
    adrecaEmpresa: '',
    codiPostalEmpresa: '',
    poblacio: '',
    dniUsuari: '',
    nomProfessional: '',
    primerCognom: '',
    secCognom: '',
    emailProfessional: '',
    telefonProfessional: '',
  };

  constructor(private fb: FormBuilder, private dialog: MatDialog) {
    this.translate.get('PERFIL.SUBTITLE').subscribe((res: string) => {
      this.tooltipText = res;
    });
  }

  async ngOnInit() {
    this.setData();
    this.breakpointObserver
      .observe(['(min-width: 916px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isWeb = true;
        }
      });
  }

  async setData() {
    const usuari = localStorage.getItem('usuari') || '{}';
    const empresa = localStorage.getItem('empresa') || '{}';
    this.usuari = JSON.parse(usuari);
    this.empresa = JSON.parse(empresa);
    try {
      const { dadesResultat } = await lastValueFrom(
        this.usuariService.getProfesionalById(this.usuari.empresaUsuariId)
      );

      // Actualizar datos de usuario
      const actualizarUsuario = { ...this.usuari };
      if (this.usuari.telefon !== dadesResultat.telefon) {
        actualizarUsuario.telefon = dadesResultat.telefon;
      }
      if (this.usuari.adrecaElectronica !== dadesResultat.adrecaElectronica) {
        actualizarUsuario.adrecaElectronica = dadesResultat.adrecaElectronica;
      }
      if (this.usuari.nom !== dadesResultat.nom) {
        actualizarUsuario.nom = dadesResultat.nom;
      }
      if (this.usuari.cognom !== dadesResultat.cognom) {
        actualizarUsuario.cognom = dadesResultat.cognom;
      }
      if (this.usuari.secCognom !== dadesResultat.secCognom) {
        actualizarUsuario.secCognom = dadesResultat.secCognom;
      }

      // Actualizar datos de empresa
      const actualizarEmpresa = { ...this.empresa };
      if (this.empresa.nif !== dadesResultat.nifEmpresa) {
        actualizarEmpresa.nif = dadesResultat.nifEmpresa;
      }
      if (this.empresa.raoSocial !== dadesResultat.raoSocial) {
        actualizarEmpresa.raoSocial = dadesResultat.raoSocial;
      }

      this.usuari = actualizarUsuario;
      localStorage.setItem('usuari', JSON.stringify(actualizarUsuario));
      this.empresa = actualizarEmpresa;
      localStorage.setItem('empresa', JSON.stringify(actualizarEmpresa));

      const image = await lastValueFrom(
        this.usuariService.getImage(
          dadesResultat.nomFitxer || '',
          this.usuari.empresaUsuariId.toString()
        )
      );
      if(image.dadesResultat.nomFitxer && image.dadesResultat.usuariFitxer64){
        const ext = image.dadesResultat.nomFitxer.split('.');
        this.image.nomFitxer = image.dadesResultat.nomFitxer;
        this.image.usuariFitxer64 = `data:image/${ext[ext.length - 1].toLowerCase()};base64,${image.dadesResultat.usuariFitxer64}`;
        this.imageOriginal = this.image;
      }
      await this.fillForm();
    } catch (err) {
      console.error(err);
    }
  }

  async fillForm() {
    if (this.usuari) {
      let poblacio = '';
      if (this.empresa.codiPoblacio) {
        this.codiPoblacio = this.empresa.codiPoblacio;
        const resp = await lastValueFrom(
          this.contextService.getPoblacions(this.empresa.codiPoblacio)
        );
        if (resp.length) poblacio = resp[0].descripcio;
      }
      this.formData = this.fb.group({
        raoSocial: [this.empresa.raoSocial, Validators.required],
        nifEmpresa: [
          { value: this.empresa.nif, disabled: true },
          Validators.required,
        ],
        rol: [this.empresa.rol, Validators.required],
        emailEmpresa: [
          this.empresa.adrecaElectronica,
          [Validators.required, Validators.email],
        ],
        telefonEmpresa: [this.empresa.telefon, [Validators.required]],
        adrecaEmpresa: [this.empresa.adreca, Validators.required],
        codiPostalEmpresa: [this.empresa.codiPostal, Validators.pattern(/^\d{5}$/)],
        poblacio: [poblacio, Validators.required],
        dniUsuari: [
          { value: this.usuari.dniUsuari, disabled: true },
          Validators.required,
        ],
        nomProfessional: [this.usuari.nom, Validators.required],
        primerCognom: [this.usuari.cognom, Validators.required],
        secCognom: [this.usuari.secCognom],
        emailProfessional: [
          this.usuari.adrecaElectronica,
          [Validators.required, Validators.email],
        ],
        telefonProfessional: [this.usuari.telefon],
      });
      this.dadesPerfil = {
        ...this.formData.value,
        nifEmpresa: this.empresa.nif,
        dniUsuari: this.usuari.dniUsuari,
        codiPerfil: this.usuari.codiPerfil,
      };
    }
  }

  toggleEditMode(): void {
    this.image = this.imageOriginal;
    this.sidepanel.open();
    this.fillForm();
  }

  setSeccio(seccio: number) {
    this.seccions[seccio] = !this.seccions[seccio];
  }

  onImageClick(): void {
    this.fileInput.nativeElement.click();
  }

  removeFile() {
    this.image = {
      usuariFitxer64: '',
      nomFitxer: '',
    };
    this.esborrada = true;
    this.file = null;
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.file = file;
    }
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => {
        this.image.usuariFitxer64 = reader.result as string;
        this.image.nomFitxer = file.name;
      };
      reader.readAsDataURL(file);
      this.esborrada = false;
    }
  }

  async onSubmit() {
    const valid = await this.showValidationErrors();
    if (valid) {
      const bodyUsuari = {
        estat: true,
        dniUsuari: this.usuari.dniUsuari,
        nifEmpresa: this.empresa.nif || '',
        nom: this.formData.value.nomProfessional,
        cognom: this.formData.value.primerCognom,
        secCognom: this.formData.value.secCognom,
        perfilId: this.usuari.perfilId,
        adrecaElectronica: this.formData.value.emailProfessional,
        telefon: this.formData.value.telefonProfessional,
        codiPerfil: this.usuari.codiPerfil,
        empresaUsuariId: this.usuari.empresaUsuariId,
        usuariId: this.usuari.usuariId,
        registrat: true,
      };
      const bodyEmpresa = {
        estat: true,
        nif: this.empresa.nif,
        raoSocial: this.formData.value.raoSocial,
        adreca: this.formData.value.adrecaEmpresa,
        codiPostal: this.formData.value.codiPostalEmpresa,
        codiPoblacio: this.codiPoblacio,
        adrecaElectronica: this.formData.value.emailEmpresa,
        telefon: this.formData.value.telefonEmpresa,
        rol: this.formData.value.rol,
        empresaId: this.empresa.empresaId,
      };
      const user = await lastValueFrom(
        this.usuariService.registraUsuari(
          bodyUsuari,
          this.usuari.empresaUsuariId
        )
      );
      if (user.validacioResultat) {
        this.errorValidator(user.validacioResultat[0]);
        return;
      }
      if (this.usuari.codiPerfil == 'PROF_ADMIN') {
        const emp = await lastValueFrom(
          this.usuariService.registraEmpresa(
            bodyEmpresa,
            this.empresa.empresaId
          )
        );
        if (emp.validacioResultat) {
          this.errorValidator(emp.validacioResultat[0]);
          return;
        }
      }
      localStorage.setItem('empresa', JSON.stringify(bodyEmpresa));
      localStorage.setItem('usuari', JSON.stringify(bodyUsuari));
      if (this.file) {
        await lastValueFrom(
          this.usuariService.setImage(this.usuari.empresaUsuariId, this.file)
        );
      } else if(this.esborrada) {
        await lastValueFrom(
          this.usuariService.deleteImage(this.usuari.empresaUsuariId)
        );
      }
      this.imageOriginal = this.image;
      this.setData();
      this.sidepanel.close();
      this.toast.open(
        'Dades actualitzades',
        "S'ha modificat l'usuari correctament"
      );
      this.sidebarToggleService.newProfilePic();
    }
  }

  errorValidator(error: any) {
    if (error.camp == 'stmcs-carregador') {
      error.codiError = 'ROL1';
    }
    if (error.camp == 'stmcs-transportista') {
      error.codiError = 'ROL2';
    }
    this.sidepanel.close();
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: 'ERRORS.'.concat(error.codiError),
        message: `En ${this.camelToNormal(error.camp)}`,
        icon: 'warning',
        showCancel: false,
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: boolean }) => {
        this.dialog.closeAll();
        this.sidepanel.open();
      }
    );
  }

  getRolValue(rol: string) {
    if (!rol) {
      return '';
    }
    if (rol.toUpperCase() === 'CARRTRANSP') {
      return 'Carregador, Transportista';
    } else {
      return rol.toLowerCase();
    }
  }

  isChecked(rol: string = '') {
    const currentRol = this.formData.value.rol;
    return currentRol.includes(rol);
  }

  onRoleChange(role: string) {
    let currentRol = this.formData.value.rol || '';
    if (this.isChecked(role)) {
      if (currentRol === 'CARRTRANSP') {
        currentRol = role === 'CARR' ? 'TRANSPORTISTA' : 'CARREGADOR';
      } else {
        currentRol = '';
      }
    } else {
      if (currentRol.length) {
        currentRol = 'CARRTRANSP';
      } else {
        currentRol = role === 'CARR' ? 'CARREGADOR' : 'TRANSPORTISTA';
      }
    }
    this.formData.patchValue({ rol: currentRol });
  }

  async changeContextPoblacio($event: any) {
    if ($event.target.value.length >= 3) {
      this.poblacions = await lastValueFrom(
        this.contextService.getPoblacions($event.target.value)
      );
    }
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    setTimeout(() => {
      this.isFocused = false;
      this.poblacions = [];
    }, 200);
  }

  setFormValue(value: string, codi: string = '') {
    this.formData.patchValue({ poblacio: value });
    this.codiPoblacio = codi;
  }

  camelToNormal = (text: string) => {
    return text
      .replace(/([A-Z])/g, ' $1')
      .toLowerCase()
      .trim()
      .replace(/\b\w/g, (char) => char.toUpperCase())
      .replace('Adreca', 'Adreça')
      .replace('Email', 'Adreça electrònica');
  };

  async showValidationErrors() {
    const requiredFields = [
      'raoSocial',
      'nifEmpresa',
      'nomProfessional',
      'rol',
      'emailEmpresa',
      'telefonEmpresa',
      'adrecaEmpresa',
      'codiPostalEmpresa',
      'poblacio',
      'dniUsuari',
      'primerCognom',
      'emailProfessional',
    ];
    const errors = []
    const obligatoris = []
    for (const field of requiredFields) {
      const control = this.formData.get(field);
      if (!control || control.value === '') {
        this.sidepanel.close();
        obligatoris.push(field)
      } else if(control.invalid) {
        this.sidepanel.close();
        errors.push(field)
      } else if(field.includes("email") && !/^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}$/.test(control.value)) {
        this.sidepanel.close();
        errors.push(field)
      }
    }
    if(obligatoris.length) {
      let msg = this.camelToNormal(obligatoris.toString());
      if(msg == "Rol") {
        msg =  "MODAL.ROL_OBLIGATORI"
      }
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: {
          title: `MODAL.OMPLI_OBLIGATORI`,
          message: `${msg}`,
          showCancel: false,
          icon: 'warning',
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(() => {
        this.dialog.closeAll();
        this.sidepanel.open();
      });
      return false;
    }
    if(errors.length) {
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: {
          title: `MODAL.FORMAT_INVALID`,
          message: `${this.camelToNormal(errors.toString())}`,
          showCancel: false,
          icon: 'warning',
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(() => {
        this.dialog.closeAll();
        this.sidepanel.open();
      });
      return false;
    }

    const resp = await lastValueFrom(
      this.contextService.getPoblacions(this.formData.value.poblacio)
    );
    const poblacio = resp.find(
      (el) => el.descripcio === this.formData.value.poblacio
    );
    if (!poblacio) {
      this.sidepanel.close();
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: {
          title: `MODAL.POBLACIO_NO_VALIDA`,
          icon: 'warning',
          showCancel: false,
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(() => {
        this.dialog.closeAll();
        this.sidepanel.open();
      });
      return false;
    }
    this.codiPoblacio = poblacio.codiElement;
    return true;
  }
}
