import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service'; 
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const bearerToken: string = localStorage.getItem("jwt") || "";
    const clonedRequest = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${bearerToken}`)
    });

    return next.handle(clonedRequest).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Respuesta exitosa
            //console.log(`Código de respuesta: ${event.status}`);
          }
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            // Respuesta de error
            //console.log(`Código de error: ${error.status}`);
          }
        }
      )
    );
  }
}
