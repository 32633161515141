import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Transportista } from '../../types/Transportista';
import { environment } from '../../../environments/environment';

interface ResponseMultiple {
  dadesResultat: Transportista[]
  validacioResultat: any,
  dadesPaginacio: any
}
interface ResponseSingle {
  dadesResultat: Transportista
  validacioResultat: any
}

@Injectable({
  providedIn: 'root'
})
export class TransportistaService {
  private apiUrl: string = environment.apiUrl+"transportista";
  
  constructor(private http: HttpClient) {}

  createTransportista(Transportista: Transportista): Observable<ResponseSingle> {
    return this.http.post<ResponseSingle>(this.apiUrl, Transportista);
  }

  getTransportistaById(id: string): Observable<ResponseSingle> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<ResponseSingle>(url);
  }

  transportistaFindBy(filters: any): Observable<ResponseMultiple> {
    const url = `${this.apiUrl}/findBy`;
    return this.http.post<ResponseMultiple>(url, filters);
  }

  updateTransportista(Transportista: Transportista): Observable<ResponseSingle> {
    const url = `${this.apiUrl}/${Transportista.objectMantId}`;
    return this.http.put<ResponseSingle>(url, Transportista);
  }

  deleteTransportista(id: number): Observable<void> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete<void>(url);
  }
 
}
