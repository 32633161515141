import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarToggleService {
  private toggleSidebarSubject = new Subject<void>();
  toggleSidebar$ = this.toggleSidebarSubject.asObservable();

  private setIconSubject = new Subject<void>();
  setIconSubject$ = this.setIconSubject.asObservable();

  private newProfilePicSubject = new Subject<void>();
  newProfilePicSubject$ = this.newProfilePicSubject.asObservable();

  emitToggleSidebar() {
    this.toggleSidebarSubject.next();
  }
  emitSetIconHeader() {
    this.setIconSubject.next();
  }
  newProfilePic() {
    this.newProfilePicSubject.next();
  }
}
