import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', '../styles.scss'],
})
export class AppComponent {
  private routerSubscription!: Subscription;
  title = 'PadelT Professionals';

  // Inicialización de ngx-translate
  constructor(
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService
  ) {
    translate.setDefaultLang('ca-ES');
    translate.use('ca-ES');
  }

  ngOnInit() {
  
  }

  useLanguage(language: string): void {
    this.translate.use(language);
  }
}
