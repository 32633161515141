import { Component, Inject, EventEmitter, Output  } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sortir-modal',
  template: `
     <div class="ctti-modal">
      <div class="cross" (click)="closeModal()">
          <mat-icon>
            close
          </mat-icon>
        </div>
        @if(data.icon) {
        <mat-icon class="center-icon tar">{{data.icon}}</mat-icon>
        }
        <h3 class="ctti-3">{{"MODAL.SEGUR" | translate}}</h3>
        <p>{{ "MODAL.SEGUR_SUB" | translate }}</p>
        <br/>
        <div class="botones">
            <div class="ctti-modal__actions gap-3">
            <button class="ctti-button ctti-button-inv" mat-dialog-close (click)="closeModal(true)">{{ "MODAL.SORTIR" | translate }}</button>
          </div>
          <div class="ctti-modal__actions gap-3">
            <button class="ctti-button ctti-button-filled" mat-dialog-close  (click)="closeModal()">{{ "MODAL.CONTINUAR" | translate }}</button>
          </div>
        </div>
     </div>
  `
})
export class SortirModalComponent {
  @Output() closeEvent = new EventEmitter<{ accept: boolean }>();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {icon: string, callback: Function },
    private dialog: MatDialog
  ) {}

  closeModal(accept: boolean = false) {
    this.closeEvent.emit({accept});
    if(this.data.callback){
      this.data.callback()
    }
  }
}
